import { message } from "antd";

const getSize = (limit) => {
  var size = "";
  if (limit < 0.1 * 1024) {
    //小于0.1KB，则转化成B
    size = limit.toFixed(2) + "B";
  } else if (limit < 0.1 * 1024 * 1024) {
    //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + "KB";
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB";
  } else {
    //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }

  var sizeStr = size + ""; //转成字符串
  var index = sizeStr.indexOf("."); //获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 2); //获取小数点后两位的值
  if (dou === "00") {
    //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
  }
  return size;
};

const exportFile = (data, filename) => {
  if (!data) {
    return;
  }
  let url = window.URL.createObjectURL(new Blob([data]));
  let link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

/**
 * 获取格式化的日期
 * @param {objct|number|string} date - 日期对象|时间戳|'2019-1-1'
 * @param {object} option - 配置对象
 * @param {boolean} option.style - 格式'YY-MM-DD hh:mm:ss'
 * @return {string} - 返回格式化的日期【"2019-01-12 16:00:00"】
 */
function formateDate(
  date,
  { style = "YY-MM-DD hh-mm-ss", frontJoin = "-" } = {
    style: "YY-MM-DD hh:mm:ss",
    frontJoin: "-",
  }
) {
  if (!date) date = new Date();
  else if (typeof date === "number" || typeof date === "string")
    date = new Date(date);
  let year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate();
  let hours = date.getHours(),
    minutes = date.getMinutes(),
    seconds = date.getSeconds();

  let mid = style.match(/\s+/),
    midJoin = style.substr(mid.index, mid.length);

  let fullRes =
    [year, month, day].map((item) => formateNumnber(item)).join(frontJoin) +
    midJoin +
    [hours, minutes, seconds].map((item) => formateNumnber(item)).join(":");
  let midInx = fullRes.indexOf(midJoin);
  let front = fullRes.slice(midInx - mid.index, midInx),
    behind = fullRes.substr(
      midInx + mid.length,
      style.length - mid.index - mid.length
    );

  return front + midJoin + behind;

  function formateNumnber(n) {
    n = n.toString();
    return n[1] ? n : "0" + n;
  }
}

function loopRoutes(config) {
  const result = [];
  function pushRoute(routersArr) {
    routersArr.forEach((item) => {
      if (item.exact) {
        result.push({
          key: item.key,
          label: item.label,
          path: item.path,
          menuFunctions: item.menuFunctions,
          exact: true,
        });
      } else if (item.children && item.children.length) {
        pushRoute(item.children);
      }
    });
  }
  pushRoute(config);
  return result;
}
//判断数据类型
function getDataType(data) {
  return Object.prototype.toString.call(data).slice(8, -1);
}

//生成随机密码
function randomPassword(length = 6) {
  var passwordArray = [
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    "abcdefghijklmnopqrstuvwxyz",
    "1234567890",
  ];
  var password = [];
  let n = 0;
  for (let i = 0; i < length; i++) {
    if (password.length < length - 3) {
      let arrayRandom = Math.floor(Math.random() * 3);
      let passwordItem = passwordArray[arrayRandom];
      let item = passwordItem[Math.floor(Math.random() * passwordItem.length)];
      password.push(item);
    } else {
      let newItem = passwordArray[n];
      let lastItem = newItem[Math.floor(Math.random() * newItem.length)];
      let spliceIndex = Math.floor(Math.random() * password.length);
      password.splice(spliceIndex, 0, lastItem);
      n++;
    }
  }
  return password.join("");
}

function filterUnvalid(requestBody) {
  let _requestBody = {};
  for (let key in requestBody) {
    if (
      requestBody[key] !== "" &&
      typeof requestBody[key] !== "undefined" &&
      requestBody[key] !== null &&
      JSON.stringify(requestBody[key]) !== '[""]'
    ) {
      _requestBody[key] = requestBody[key];
    }
  }
  return _requestBody;
}
function exportExcel(filename, url = "/web/file/download_obs", fileUrl) {
  const token =
    (JSON.parse(sessionStorage.getItem("accessToken")) &&
      JSON.parse(sessionStorage.getItem("accessToken"))["access_token"]) ||
    "";

  if (!filename || !url) {
    return;
  }
  let xhr = new XMLHttpRequest();
  xhr.onload = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let data = xhr.response;
      let blobUrl = window.URL.createObjectURL(data);
      if (data.code && data.code !== 0) {
        message.error(data.message);
      } else {
        let alink = document.createElement("a");
        alink.download = filename;
        alink.style.display = "none";
        alink.href = blobUrl;
        alink.click();
        window.URL.revokeObjectURL(alink.href);
      }
    }
  };
  xhr.open("get", `${url}?fileName=${filename}&&fileUrl=${fileUrl}`, true);
  // xhr.setRequestHeader("access-token", token);
  xhr.responseType = "blob";
  // 发送请求
  xhr.send(null);
}
export {
  getSize,
  exportFile,
  formateDate,
  loopRoutes,
  getDataType,
  randomPassword,
  filterUnvalid,
  exportExcel
};
