import { message } from 'antd';
import moment from "moment";
// 常用api
const mockRemoteRequest = (cons, defaultRes, delay) => {
  let timeout;
  return new Promise((resolve, reject) => {
    timeout = setTimeout(() => {
      resolve(defaultRes || 'it is respone');
    }, delay || 5000);
  }).then((res) => {
    clearTimeout(timeout);
    return res;
  });
};

const loadingMask = (msg, duration, onclose) => {
  message.warning.apply(message, [msg, duration, onclose].filter(ele => ele !== undefined));
};
// 组装新增或编辑的参数
const postValues = (inputsWrapper) => {
  const conditionsVal = {};
  let keyIndex = 0;
  for (let item of inputsWrapper) {
    let key = item.current.props.keys;
    let val = item.current.getValue()
    if (key.match(/startTime|endTime/)) {
      val = moment(val).format('YYYY-MM-DD HH:mm:ss')
    }
    if (key) {
      conditionsVal[key] = item.current.getValue && item.current.getValue();
    }
  }
  return conditionsVal;
};
const filter = (map, key) => {
  let resp;
  for (let [k, v] of map) {
    if (key === k) {
      resp = v;
    }
    if (key === v) {
      resp = k;
    }
    if (key === undefined) {
      resp = resp || [];
      resp.push({
        label: v,
        value: k
      })
    }
  }
  return resp;
}
// 单据状态
const applyState = (key) => {
  let map = new Map();
  map.set(1, "未审核");
  map.set(2, "已审核");
  map.set(-2, "已作废");
  return filter(map, key);
}
// 单据来源
const applyFrom = (key) => {
  let map = new Map();
  map.set(1, "平台");
  map.set(2, "NC");
  map.set(3, "APP");
  return filter(map, key);
}
// 其他入库磅单单据来源
const poundFrom = (key) => {
  let map = new Map();
  map.set(1, "计量系统");
  map.set(2, "补增");
  return filter(map, key);
}
// 单据 1：启用 0：禁用
const applySwitch = (key) => {
  let map = new Map();
  map.set(1, "启用");
  map.set(2, "禁用");
  return filter(map, key);
}
// 车辆类型 1:临时车 2:固定车
const applyVehicleType = (key) => {
  let map = new Map();
  map.set(1, "临时车");
  map.set(2, "固定车");
  return filter(map, key);
}
// 运输类型 1:非倒运 2:倒运
const applyTransportType = (key) => {
  let map = new Map();
  map.set(1, "非倒运");
  map.set(2, "倒运");
  return filter(map, key);
}
// 包装类型 1:袋装 2:散装
const applyPackageType = (key) => {
  let map = new Map();
  map.set(1, "袋装");
  map.set(2, "散装");
  return filter(map, key);
}
// 是否固定 1:是，0:否
const applyIsFixation = (key) => {
  let map = new Map();
  map.set(1, "是");
  map.set(2, "否");
  return filter(map, key);
}
// 单据类型 1:红冲，2:补增，3:作废
const applyType = (key) => {
  let map = new Map();
  map.set(1, "红冲");
  map.set(2, "补增");
  map.set(3, "作废");
  return filter(map, key);
}
// 车辆状态（1：未入厂，2：入厂，3：一次过磅，41：装车，42：卸车，5：二次过磅，6：出厂，15：强制过磅，16：异常出厂）
const applyVehicleStatus = (key) => {
  let map = new Map();
  map.set("", "全部");
  map.set(1, "未入厂");
  map.set(2, "入厂");
  map.set(3, "一次过磅");
  map.set(41, "装车");
  map.set(42, "卸车");
  // map.set(5, "二次过磅");
  map.set(6, "出厂");
  map.set(15, "强制过磅");
  map.set(16, "异常出厂");
  return filter(map, key);
}
// 查询厂内倒运磅单 单据状态：（1:正常，4:作废）
const applyPoundType = (key) => {
  let map = new Map();
  map.set(1, "正常");
  map.set(2, "已红冲");
  map.set(3, "已参照");
  map.set(4, "作废");
  return filter(map, key);
}
// 其他入库申请单（1：未审核，2：已审核，-2：已作废, -1：已关闭，0：已过期）
const otherApplyType = (key) => {
  let map = new Map();
  map.set("", "全部");
  map.set(1, "未审核");
  map.set(2, "已审核");
  map.set(-2, "已作废");
  map.set(-1, "已关闭");
  // map.set(0, "已过期");
  return filter(map, key);
}
// 销售袋装、销售散装、采购
// 在场车辆控制-业务类型 （1：销售袋装，2：销售散装，3：采购业务, 4：其他出库业务，5：其他入库业务）
const vehicleControlType = (key) => {
  let map = new Map();
  map.set(1, "销售袋装");
  map.set(2, "销售散装");
  map.set(3, "采购业务");
  map.set(4, "其他出库业务");
  map.set(5, "其他入库业务");
  return filter(map, key);
}
// 表格数据转换
const tableList = (response) => {
  let res = response;
  if (!res.entity.list) {
    res.entity = {
      list: res.entity,
      listSize: res.entity.length,
      pageIndex: 1,
      pageSize: 20,
      total: res.entity.length
    }
  }
  return res;
}

function menuToObj(config) {
  const result = {};
  function loopChildren(routersArr) {
    routersArr.forEach(item => {
      if (item.key) {
        result[item.key] = {
          key: item.key,
          label: item.label,
          icon: item.icon
        }
      }
      if (item.children && item.children.length) {
        loopChildren(item.children);
      } else {
        result[item.key].path = item.path;
        result[item.key].exact = true;
        result[item.key].render = item.render;
      }
    })
  }
  loopChildren(config);
  return result;
}
// 循环处理返回的权限菜单
function menuLoop(config, obj) {
  let result = config;
  function loopChildren(routersArr) {
    routersArr.forEach(item => {
      let ccitem = obj[item.menuCode];
      if (ccitem) {
        item.key = item.menuCode;
        item.label = item.menuName;
        item.path = ccitem.path;
        item.exact = ccitem.exact;
        item.render = ccitem.render;
      }
      if (item.children && item.children.length) {
        loopChildren(item.children);
      }
    })
  }
  loopChildren(config);
  return result;
}
function setPostValue(modal, key, obj) {
  let inputs = modal.props.inputsWrapper;
  if (inputs) {
    inputs.forEach(item => {
      try {
        if (item.current.props.keys === key) {
          item.current.setValue(obj[key + 'Name'])
          item.current.setPostValue(obj)
        }
      } catch (e) { }
    })
  }
}
// 人民币转换为大写
function convertMoney(money) {
  //汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟');
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆');
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分');
  var cnDecUnits = new Array('', '');
  //整数金额时后面跟的字符
  var cnInteger = '整';
  cnInteger = ''
  //整型完以后的单位
  var cnIntLast = '元';
  cnIntLast = ''
  var cnDot = '点';
  // 如果为负数
  var cnIntMinus = '负';
  // 负号处理后的数字
  var cash;
  //分离金额后用的数组，预定义
  var parts;
  var integerNum;
  var decimalNum;
  var chineseStr = '';
  if (money === "" || money === undefined) {
    return "";
  }
  money = money + '';
  if (money.charAt(0) === "-") {
    cash = money.slice(1);
  } else {
    cash = money;
  }
  cash = parseFloat(cash);
  if (cash == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }

  cash = cash.toString();
  if (cash.indexOf('.') == -1) {
    integerNum = cash;
    decimalNum = '';
  } else {
    parts = cash.split(".");
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 2);
  }

  if (integerNum != '-' && parseInt(integerNum, 10) > 0) {
    var intLen = integerNum.length;
    var zero = 0;
    for (let i = 0; i < intLen; i++) {
      var intChar = integerNum.substr(i, 1);
      var intSlen = intLen - i - 1;
      var divided = intSlen / 4;
      var remain = intSlen % 4;

      if (intChar == "0") {
        zero++;
      } else {
        if (zero > 0) {
          chineseStr += cnNums[0];
        }
        zero = 0;
        chineseStr += cnNums[parseInt(intChar)] + cnIntRadice[remain];
      }
      if (remain === 0 && divided > 0) {
        chineseStr += cnIntUnits[divided];
      }
    }
    chineseStr += cnIntLast;
  }

  if (decimalNum != '') {
    var decLen = decimalNum.length;
    chineseStr += cnDot;
    for (let i = 0; i < decLen; i++) {
      var decChar = decimalNum.substr(i, 1);
      if (decChar != '0') {
        chineseStr += cnNums[parseInt(decChar)] + cnDecUnits[i];
      }
      if (decChar == '0' && parseInt(integerNum, 10) > 0) {
        chineseStr += cnNums[parseInt(decChar)] + cnDecUnits[i];
      }
    }
  } else {
    chineseStr += cnInteger;
  }
  return chineseStr;
}
function selectRow(selectId, record, that, callback){
  if (that.state.selectedRow[0] === selectId[0]) {
    that.setState({
      detailRecord: that.state.detailRecord ? null : record[0],
    });
  } else {
    that.setState({
      detailRecord: record[0]
    });
  }
  that.setState({
    selectedRow: selectId
  }, () => {
    if(callback){
      callback();
    }
  });
};
function refresh(that) {
  that.setState({
    refreshloading: true
  }, () => {
    that.reLoadData();
  })
};
function reLoadData(that) {
  that.refs.ref_pageContent.refresh();
  setTimeout(() => {
    that.setState({
      refreshloading: false
    })
  }, 1000)
}
export {
  mockRemoteRequest,
  loadingMask,
  postValues,
  applyState,
  applyFrom,
  poundFrom,
  applySwitch,
  applyVehicleType,
  applyTransportType,
  applyPackageType,
  applyIsFixation,
  applyType,
  applyVehicleStatus,
  applyPoundType,
  otherApplyType,
  vehicleControlType,
  tableList,
  menuToObj,
  menuLoop,
  setPostValue,
  convertMoney,
  selectRow,
  refresh,
  reLoadData
}
