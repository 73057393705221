import React, { Component } from "react";
import { Button } from "antd";
import "./index.scss";
import { inject, observer } from "mobx-react";
@inject("GlobalStore")
@observer
class BaseButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <Button
        loading={this.props.loading}
        disabled={this.props.disabled || this.props.GlobalStore?.[this.props.keys]}
        className={this.props.className}
        onClick={this.click}
      >
        {this.props.content}
      </Button>
    );
  }
}

export default BaseButton;
