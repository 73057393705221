import React, { Component } from 'react';
import style from './index.module.scss';
import { Input } from 'antd';
const { Search, Password } = Input;

function renderLabel(that) {
  return (
    <span
      className={`${style.labelWrapper} labelDefault`}
      style={that.props.labelStyle}
    >
      <span className={style.defaultLabel}>{that.props.label}</span>
    </span>
  );
  // if (typeof that.props.label === 'string') {
  //     return <span className={style.defaultLabel} style={that.props.labelStyle}>{that.props.label}</span>
  // } else {
  //     return that.props.label;
  // }
}
function getRegExp(rule) {
  if (rule) {
    if (typeof rule === 'object') return rule;
    return new RegExp(rule);
  }
}
class index extends Component {
  state = {
    isSearch: this.props.isSearch, // 是否可搜索，默认否
    inputStyle: Object.assign(
      {
        width: '188px',
      },
      this.props.inputStyle
    ),
    defaultValue: this.props.defaultValue,
    value: this.props.defaultValue,
    required: this.props.required,
    requiredMsg: this.props.requiredMsg,
    rule: getRegExp(this.props.rule),
    ruleMsg: this.props.ruleMsg,
    disabled: this.props.disabled,
    disabledInput: this.props.disabledInput, //不可输入，供点击使用
    validateResult: true,
    validateMsg: '',
    valueFormate: getRegExp(this.props.valueFormate), //用于限制输入，可用于数字输入等
  };

  valueChange = e => {
    if (this.state.disabledInput) return;
    let value = e.target.value;
    if (
      this.state.valueFormate &&
      value != '' &&
      !this.state.valueFormate.test(value)
    ) {
      return;
    }
    if (this.props.transValueFunc) value = this.props.transValueFunc(value);
    this.setState({ value }, () => {
      this.props.onChange && this.props.onChange(this.state.value);
    });
  };
  onClick = e => {
    this.props.onClick && this.props.onClick();
  };
  onBlur = e => {
    this.validate();
  };
  onSearch = e => {
    this.props.onSearch && this.props.onSearch();
  };
  getValue = () => {
    return this.state.value;
  };
  setValue = val => {
    // 根据val设置value,并且可能需要改变相应的样式
    this.setState({ value: val }, () => {
      this.onBlur();
    });
  };
  reset = () => {
    this.setState({ value: this.props.defaultValue });
  };
  setDisabled = val => {
    this.setState({ disabled: val });
  };
  validate = () => {
    // return false表示验证不成功 return true表示验证成功
    // 验证不成功可能需要在底部提示requiredMsg或者ruleMsg
    if (this.state.required) {
      if (!this.state.value) {
        this.setState({
          validateResult: false,
          validateMsg: this.state.requiredMsg,
        });
        return false;
      }
    }
    if (this.state.rule && this.state.value) {
      if (!this.state.rule.test(this.state.value)) {
        this.setState({
          validateResult: false,
          validateMsg: this.state.ruleMsg,
        });
        return false;
      }
    }
    this.setState({
      validateResult: true,
      // validateMsg: ''
    });
    return true;
  };

  render() {
    return (
      <div
        className={`${style['bfb-textInput']} ${
          this.state.required ? style.required : ''
        } ${this.state.validateResult ? '' : style['validateResult-false']}`}
      >
        {/* <span className={`${style.labelWrapper} labelDefault`}> */}
        {renderLabel(this)}
        {/* </span> */}
        <div className={style.inputWrapper}>
          {this.state.isSearch ? (
            <Search
              className={style['searchInput']}
              placeholder={this.props.placeholder}
              onChange={this.valueChange}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
              value={this.state.value}
              disabled={this.state.disabled}
              style={this.state.inputStyle}
            />
          ) : this.props.password ? (
            <Password
              className={this.props.onClick ? style.clickInput : ''}
              placeholder={this.props.placeholder}
              onChange={this.valueChange}
              onBlur={this.onBlur}
              onClick={this.onClick}
              value={this.state.value}
              disabled={this.state.disabled}
              style={this.state.inputStyle}
              maxLength={this.props.maxLength}
            />
          ) : (
            <Input
              className={this.props.onClick ? style.clickInput : ''}
              placeholder={this.props.placeholder}
              onChange={this.valueChange}
              onBlur={this.onBlur}
              onClick={this.onClick}
              value={this.state.value}
              disabled={this.state.disabled}
              style={this.state.inputStyle}
              maxLength={this.props.maxLength}
            />
          )}
          <span className={style.validateMsg}>{this.state.validateMsg}</span>
        </div>
      </div>
    );
  }
}

export default index;
