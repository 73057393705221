import { configure } from 'mobx'; // 开启严格模式
const contexts = require.context('./module', false, /\.js/);
let _stores = {}

contexts.keys().forEach(key => {
  const name = /\.\/(\S+).js$/g.exec(key);
  _stores[name[1]] = contexts(key).default
});

// configure({ enforceActions: 'always' }); // 开启严格模式
configure({ enforceActions: 'observed' });
const stores = {
  ..._stores
};

export default stores


//严格模式:操作数据源的唯一操作就是通过action。数据流变得可追溯了~