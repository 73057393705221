import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import style from './index.module.scss';
import { Badge, Menu, Dropdown, Radio, message } from 'antd';
import Modal from '@/components/Modal';
import TextInput from '@/components/Inputs/TextInput';
import md5 from 'js-md5';
const dateDayNums = ['天', '一', '二', '三', '四', '五', '六'];
@inject('GlobalStore')
@observer
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      userInfo: {},
      currentPartyId: null,
    };
  }
  componentWillMount() {
    this.tick();
    if (this.props.GlobalStore.userInfo) {
      this.setState({
        userInfo: this.props.GlobalStore.userInfo,
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  userHandle = ({ key }) => {
    switch (key) {
      case 'item_0':
        this.setState({ currentPartyId: this.state.userInfo.partyId });
        this.refs.ref_modal_chooseFactory.showModal();
        break;
      case 'item_1':
        this.refs.ref_modal_changePassword.showModal();
        break;
      case 'item_2':
        this.refs.ref_modal_logout.showModal();
        break;
    }
  };
  //退出
  logoutOk = () => {
    this.props.GlobalStore.logout();
    window.location.href = '#/login';
  };
  //切换厂区
  chooseFactory = () => {
    const currentPartyId = this.state.currentPartyId;
    const partyName = this.state.userInfo.partyDTOS.filter(
      item => item.partyId === currentPartyId
    )[0].partyName;
    this.refs.ref_modal_chooseFactory.showLoading();
    Promise.all([
      global.API.user.chooseFactory({
        partyId: currentPartyId,
        partyName,
      }),
      global.API.user.getOrgByFactoryId({
        factoryId: currentPartyId,
        pageIndex: 1,
        pageSize: 20,
      }),
    ])
      .then(res => {
        if (res[0] && res[0].entity && res[1] && res[1].entity) {
          let entity0 = res[0].entity;
          let entity1 = res[1].entity?.list;
          entity0.orgId = entity1[0] ? entity1[0].id : '';
          this.props.GlobalStore.login(entity0);
          this.props.GlobalStore.saveOrgInfo(entity1);
          this.refs.ref_modal_chooseFactory.hideLoading();
          window.location.reload();
        }
      })
      .catch(() => {
        this.refs.ref_modal_chooseFactory.hideLoading();
      });
  };
  onFactoryChange = e => {
    this.setState({
      currentPartyId: e.target.value,
    });
  };
  //修改密码
  changePassword = () => {
    const validateRes = this.refs.ref_modal_changePassword.validate();
    if (validateRes[0].length) return;
    if (validateRes[1].newPassword != validateRes[1].newPasswordConfirm) {
      message.error('新密码两次输入不一致');
      return;
    }
    this.refs.ref_modal_changePassword.showLoading();
    global.API.user
      .changePassword({
        oldPassword: md5(validateRes[1].oldPassword),
        newPassword: md5(validateRes[1].newPassword),
      })
      .then(res => {
        if (res && res.entity) {
          message.success('修改成功');
          this.refs.ref_modal_changePassword.hideModal();
        }
      })
      .finally(res => {
        this.refs.ref_modal_changePassword.hideLoading();
      });
  };
  tick() {
    this.setState({
      date: this.formateDate(),
    });
    this.timerID = setInterval(() => {
      this.setState({
        date: this.formateDate(),
      });
    }, 1000);
  }
  formateDate(time) {
    if (!time) time = new Date();
    let hours = this.formateNumnber(time.getHours()),
      minutes = this.formateNumnber(time.getMinutes()),
      seconds = this.formateNumnber(time.getSeconds());
    return [
      `${time.getFullYear()}年${time.getMonth() + 1}月${time.getDate()}日`,
      `星期${dateDayNums[time.getDay()]}`,
      `${hours}:${minutes}:${seconds}`,
    ];
  }
  formateNumnber(n) {
    n = n.toString();
    return n[1] ? n : '0' + n;
  }
  goHome = () => {
    window.location.href = '#/home';
  };
  render() {
    const menu = (
      <Menu onClick={this.userHandle}>
        <Menu.Item>
          <div className="userArea">
            <img src={require('@/assets/icon/toggle.png')} alt="切换厂区" />
            <span>切换厂区</span>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="userArea">
            <img src={require('@/assets/icon/edit.png')} alt="修改密码" />
            <span>修改密码</span>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="userArea">
            <img src={require('@/assets/icon/close.png')} alt="退出" />
            <span>退出</span>
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <div className={style.header}>
          <div className={style.left} onClick={this.goHome}>
            <img
              className={style.logo}
              src={require('@/assets/img/logo-b.svg')}
              alt="logo"
            />
            <div className={style.spot}></div>
            <span className={style.systemName}>智能物流云平台</span>
          </div>
          <div className={style.mid}>
            <span>{this.state.date[0]}</span>
            <span>{this.state.date[1]}</span>
            <span>{this.state.date[2]}</span>
          </div>
          <div className={style.right}>
            <div className={[`${style.componyName} overflow_3`]}>
              {this.state.userInfo.partyName}
            </div>
            <a href="/#/home" className={style.bellWrapper}>
              <Badge showZero count={0} style={{ backgroundColor: '#E02020' }}>
                <img
                  className={style.bell}
                  src={require('@/assets/icon/bell.png')}
                  alt="消息"
                ></img>
              </Badge>
            </a>

            <div className={style.user}>
              <Dropdown overlay={menu}>
                <img
                  className={style.avatar}
                  src={require('@/assets/icon/avatar.png')}
                  alt="avatar"
                />
              </Dropdown>
              <div className={style.userInfo}>
                <span className={style.name}>{this.state.userInfo.name}</span>
                <div className={style.number}>
                  登录账号
                  {this.state.userInfo.cellPhone ||
                    this.state.userInfo.telephone}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={false}
          title="厂区选择"
          ref="ref_modal_chooseFactory"
          width="680px"
          onOk={this.chooseFactory}
        >
          <Radio.Group
            onChange={this.onFactoryChange}
            value={this.state.currentPartyId}
          >
            {this.state.userInfo.partyDTOS &&
              this.state.userInfo.partyDTOS.map(item => {
                return (
                  <Radio key={item.partyId} value={item.partyId}>
                    {item.partyName}
                  </Radio>
                );
              })}
          </Radio.Group>
        </Modal>
        <Modal
          visible={false}
          title="修改密码"
          ref="ref_modal_changePassword"
          width="480px"
          onOk={this.changePassword}
        >
          <div style={{ textAlign: 'center' }}>
            <TextInput
              password
              isInput
              labelStyle={{ width: '48px' }}
              key="oldPassword"
              label="旧密码"
              ref={React.createRef()}
              placeholder="请输入旧密码"
              required
              requiredMsg="旧密码不能为空"
            />
          </div>
          <div style={{ marginTop: '14px', textAlign: 'center' }}>
            <TextInput
              password
              isInput
              labelStyle={{ width: '48px' }}
              key="newPassword"
              label="新密码"
              ref={React.createRef()}
              placeholder="请输入新密码"
              required
              requiredMsg="新密码不能为空"
              rule={'^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$'}
              ruleMsg={'请输入6-18位数字和字母组合'}
            />
          </div>
          <div style={{ marginTop: '14px', textAlign: 'center' }}>
            <TextInput
              password
              isInput
              labelStyle={{ width: '48px' }}
              key="newPasswordConfirm"
              label="确认密码"
              ref={React.createRef()}
              placeholder="请再次输入新密码"
              required
              requiredMsg="请再次输入新密码"
            />
          </div>
        </Modal>
        <Modal
          visible={false}
          title="退出"
          ref="ref_modal_logout"
          width="500px"
          onOk={this.logoutOk}
        >
          确认退出登录？
        </Modal>
      </>
    );
  }
}

export default index;
