import React, { Component } from "react";
import "./index.scss";
import Menu from "@/components/Menu";
import MenuHistory from "@/components/MenuHistory";
import { routeView } from "@/router/index.js";
import { inject, observer } from "mobx-react";
import {
  Route,
  Redirect,
  Switch,
  HashRouter as Router,
} from "react-router-dom";

const footer = (
  <div id="footer">
    <span>豫ICP备16036132号</span> <span>POWERD BY DAYI TECHNOLOGY</span>
  </div>
);
@inject("GlobalStore")
@observer
class index extends Component {
  state = {
    show: false
  }
  componentDidMount() {}
  showHistory = () => {
    this.setState({
      show: true
    })
  }
  render() {
    let styleObj = {
      top: this.props.GlobalStore.isShowNotice ? "106px" : "66px"
    };
    return (
      <div id="mainContent" style={styleObj}>
        <Menu showHistory={this.showHistory} />
        <div id="mainContent-right">
          {this.state.show ? <MenuHistory /> : <></>}
          <div id="maincontent-routerView">
            <Switch>{routeView("index")}</Switch>
            {footer}
          </div>
        </div>
      </div>
    );
  }
}

export default index;
