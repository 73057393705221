import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import './index.scss';
import routersConfig from '@/router/config.js';
import { loopRoutes } from '@/utils/tool.js';
import * as documentApi from '@/utils/useful.js';
import { Menu } from 'antd';
const { SubMenu } = Menu;
let routers = [];

@inject('GlobalStore')
@observer
class MyMenu extends Component {
  state = {
    collapsed: false,
    selectedKeys: 'home',
    routersConfig: [],
    style: this.props.style,
  };
  componentWillUnmount() {}
  componentDidMount() {
    let query = {
      needNestTree: true,
    };
    // if (
    //   window.location.hostname === 'localhost' ||
    //   window.location.hostname === '127.0.0.1' ||
    //   window.location.hostname === '0.0.0.0'
    // ) {
    //   this.handleData(routersConfig);
    //   return;
    // }
    global.API.system.permAuthedMenuFunctionsQuery(query).then(res => {
      if (res && res.code === 200) {
        let response = documentApi.tableList(res);
        let treeData = response.entity.list;
        this.handleData(treeData);
      }
    });
  }
  handleData = treeData => {
    let obj = documentApi.menuToObj(routersConfig);
    let result = documentApi.menuLoop(treeData, obj);
    this.setState({
      routersConfig: result,
    });
    routers = loopRoutes(result);
    this.props.GlobalStore.setGlobalRoutes(routers);
    this.props.showHistory();
    this.initSelectedKeys(window.location.href);

    this.props.history.listen(param => {
      // console.log('history-listen',param)
      this.initSelectedKeys(`#${param.pathname}`);
    });
  };
  initSelectedKeys = url => {
    const key = url.split('#/')[1];
    if (key === 'home') {
      this.setState({
        selectedKeys: 'home',
      });
      return;
    }
    for (let item of routers) {
      if (item.key === key) {
        this.setState({
          selectedKeys: key,
        });
        return;
      }
    }
  };
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  getMenu() {
    function loopRouters(arr) {
      return (
        arr &&
        arr.length > 0 &&
        arr.map(item => {
          if (item.exact) {
            return (
              <Menu.Item key={item.key}>
                {item.label}
                <Link to={item.path}></Link>
              </Menu.Item>
            );
          } else {
            return (
              <SubMenu
                key={item.key}
                title={
                  item.icon ? (
                    <div className="menu-f">
                      <img
                        src={require('../../assets/icon/' + item.icon)}
                        alt="icon"
                      />
                      <span>{item.label}</span>
                    </div>
                  ) : (
                    item.label
                  )
                }
              >
                {loopRouters(item.children)}
              </SubMenu>
            );
          }
        })
      );
    }
    return (
      <Menu
        inlineCollapsed={this.state.collapsed}
        mode="inline"
        className="menuStyleCustom"
        onClick={this.handleClick}
        selectedKeys={[this.state.selectedKeys]}
      >
        {loopRouters(this.state.routersConfig)}
      </Menu>
    );
  }
  handleClick = e => {
    console.log('click ', e);
    this.setState({
      selectedKeys: e.key,
    });
  };
  render() {
    const MenuToggler1 = (
      <div className="toggler1" onClick={this.toggleCollapsed}>
        <img
          className="arrow"
          src={require('@/assets/icon/arrow-left.png')}
          alt="收起"
        />
        <span className="text">收起</span>
      </div>
    );
    const MenuToggler2 = (
      <div className="toggler2" onClick={this.toggleCollapsed}>
        <img
          className="arrow"
          src={require('@/assets/icon/arrow-right.png')}
          alt="展开"
        />
      </div>
    );
    return (
      <div
        id="menu-wrapper"
        className={
          this.state.collapsed ? 'menu-wrapper-close' : 'menu-wrapper-open'
        }
      >
        <div className="menuToggler">
          {this.state.collapsed ? MenuToggler2 : MenuToggler1}
        </div>
        <div className="menu-content-wrapper">{this.getMenu()}</div>
      </div>
    );
  }
}

export default withRouter(MyMenu);
