import React, { Component } from 'react'
import "./index.scss";
// import { notification } from 'antd';
class index extends Component {
    componentDidMount() {
        this.getNotice();
    }
    getNotice() {

    }
    render() {
        return (
            <div id="globalNotice">
                <div className="inner">
                    <span className="content">预留浮动公告预留浮动公告预留浮动公告预留浮动公告</span>
                </div>
                <span className="close">×</span>

            </div>
        )
    }
}

export default index