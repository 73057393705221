import loadable from "@/utils/loadable.js";

const SaleApply = loadable(() => import("@/page/Sale/SaleApply"));
const SaleArrivalNotice = loadable(() =>
  import("@/page/Sale/SaleArrivalNotice")
);
const SalePoundList = loadable(() => import("@/page/Sale/SalePoundList"));
const SalePlan = loadable(() => import("@/page/Sale/SalePlan"));
const SaleContract = loadable(() => import("@/page/Sale/SaleContract"));
const SaleContractDetail = loadable(() =>
  import("@/page/Sale/SaleContract/components/Detail")
);
//调价协议
const PriceAdjustAgree = loadable(() => import("@/page/Sale/PriceAdjustAgree"));
//新增调价协议
const PriceAdjustAgreeAdd = loadable(() =>
  import("@/page/Sale/PriceAdjustAgree/components/Add")
);
//调价协议详情
const PriceAdjustAgreeDetail = loadable(() =>
  import("@/page/Sale/PriceAdjustAgree/components/Detail")
);

const buyApply = loadable(() => import("@/page/Buy/buyApply"));
const buyPoundList = loadable(() => import("@/page/Buy/buyPoundList"));
const BuyPlan = loadable(() => import("@/page/Buy/BuyPlan"));
const buySourceSetting = loadable(() =>
  import("@/page/Buy/buySetting/buySourceSetting")
);

const arrivalNotice = loadable(() => import("@/page/Buy/arrivalNotice"));

const weightApproval = loadable(() =>
  import("@/page/manage/businessApproval/weightApproval")
);

const customerBalance = loadable(() =>
  import("@/page/manage/financyManager/customerBalance")
);
const financeDetail = loadable(() =>
  import("@/page/manage/financyManager/financeDetail")
);
const PayOrder = loadable(() =>
  import("@/page/manage/PayOrderManage/PayOrder")
);
const UpstreamFlow = loadable(() =>
  import("@/page/manage/PayOrderManage/UpstreamFlow")
);

const SplitAccount = loadable(() =>
  import("@/page/manage/PayOrderManage/SplitAccount")
);

const buyDetailEachCar = loadable(() =>
  import("@/page/manage/reportFormManage/buyReportForm/buyDetailEachCar")
);
const buySupplierCount = loadable(() =>
  import("@/page/manage/reportFormManage/buyReportForm/buySupplierCount")
);
const saleDetailEachCar = loadable(() =>
  import("@/page/manage/reportFormManage/saleReportForm/saleDetailEachCar")
);
const saleCustomerCount = loadable(() =>
  import("@/page/manage/reportFormManage/saleReportForm/saleCustomerCount")
);
const transportDetailEachCar = loadable(() =>
  import(
    "@/page/manage/reportFormManage/transportReportForm/transportDetailEachCar"
  )
);
const transportMaterial = loadable(() =>
  import("@/page/manage/reportFormManage/transportReportForm/transportMaterial")
);

const userManage = loadable(() => import("@/page/setting/userManage"));
const roleManage = loadable(() => import("@/page/setting/roleManage"));
const loginLog = loadable(() => import("@/page/setting/systemLog/loginLog"));
const replyLog = loadable(() => import("@/page/setting/systemLog/replyLog"));
const noticeLog = loadable(() => import("@/page/setting/systemLog/noticeLog"));
// const noteLog = loadable(() => import('@/page/setting/systemLog/noteLog'));
const otherOptionLog = loadable(() =>
  import("@/page/setting/systemLog/otherOptionLog")
);
const poundLog = loadable(() => import("@/page/setting/systemLog/poundLog"));
const processSet = loadable(() => import("@/page/setting/processSetting"));

const FileCustomer = loadable(() => import("@/page/File/Customer"));
const FileSupplier = loadable(() => import("@/page/File/Supplier"));
const FileCarManageList = loadable(() =>
  import("@/page/File/CarManage/CarManageList")
);
const FileCarBlackList = loadable(() =>
  import("@/page/File/CarManage/CarBlackList")
);
const SpecialVehicle = loadable(() =>
  import("@/page/File/CarManage/SpecialVehicle")
);

const FileDriver = loadable(() => import("@/page/File/Driver"));
const FileMaterial = loadable(() => import("@/page/File/Material"));
const CustomPrice = loadable(() => import("@/page/File/CustomPrice"));
const SaleOverload = loadable(() => import("@/page/File/SaleOverload"));
const FileYard = loadable(() => import("@/page/File/Yard"));
// IOT
const EquipmentManage = loadable(() =>
  import("@/page/File/IOT/EquipmentManage")
);
const ProductManage = loadable(() => import("@/page/File/IOT/ProductManage"));

const OtherInTransportNotice = loadable(() =>
  import("@/page/Other/InTransportNotice")
);
const OtherInTransportPoundList = loadable(() =>
  import("@/page/Other/InTransportPoundList")
);
//审批流程
const ApprovalProcess = loadable(() =>
  import("@/page/setting/ApprovalProcess")
);
//新增审批流
const AddProcess = loadable(() =>
  import("@/page/setting/ApprovalProcess/components/Add")
);
const menuIconUrls = [
  "assets/icon/menu-sale.png",
  "assets/icon/menu-buy.png",
  "assets/icon/menu-other.png",
  "assets/icon/menu-manage.png",
  "assets/icon/menu-file.png",
  "assets/icon/menu-setting.png",
];

export default [
  {
    key: "sale",
    label: "销售业务",
    icon: menuIconUrls[0],
    children: [
      {
        key: "saleApply",
        label: "销售申请单",
        path: "/saleApply",
        exact: true,
        render: SaleApply,
      },
      {
        key: "saleArrivalNotice",
        label: "提货通知单",
        path: "/saleArrivalNotice",
        exact: true,
        render: SaleArrivalNotice,
      },
      {
        key: "salePoundList",
        label: "销售磅单",
        path: "/salePoundList",
        exact: true,
        render: SalePoundList,
      },
      {
        key: "Sales_plan",
        label: "销售计划",
        path: "/Sales_plan",
        exact: true,
        render: SalePlan,
      },
      // {
      //   key: "saleContract",
      //   label: "合同管理",
      //   path: "/saleContract",
      //   exact: true,
      //   render: SaleContract,
      // },
      // {
      //   key: "Sales_Contract_detail",
      //   label: "合同管理详情",
      //   path: "/Sales_Contract_detail/:id",
      //   exact: true,
      //   render: SaleContractDetail,
      //   isMenu: false,
      // },
      // {
      //   key: "saleAdjustPrice",
      //   label: "调价协议",
      //   path: "/saleAdjustPrice",
      //   exact: true,
      //   render: PriceAdjustAgree,
      // },
      // {
      //   key: "PriceAdjustAgreeAdd",
      //   label: "新增调价协议",
      //   path: "/PriceAdjustAgreeAdd/:id",
      //   exact: true,
      //   render: PriceAdjustAgreeAdd,
      //   isMenu: false,
      // },
      // {
      //   key: "PriceAdjustAgreeDetail",
      //   label: "调价协议详情",
      //   path: "/PriceAdjustAgreeDetail/:id",
      //   exact: true,
      //   render: PriceAdjustAgreeDetail,
      //   isMenu: false,
      // },
    ],
  },
  {
    key: "buy",
    label: "采购业务",
    icon: menuIconUrls[1],
    children: [
      {
        key: "buyApply",
        label: "采购申请单",
        path: "/buyApply",
        exact: true,
        render: buyApply,
      },
      {
        key: "arrivalNotice",
        label: "到货通知单",
        path: "/arrivalNotice",
        exact: true,
        render: arrivalNotice,
      },
      {
        key: "buyPoundList",
        label: "采购磅单",
        path: "/buyPoundList",
        exact: true,
        render: buyPoundList,
      },
      {
        key: "buySetting",
        label: "采购设置",
        children: [
          {
            key: "buySourceSetting",
            label: "采购原发设置",
            path: "/buySourceSetting",
            exact: true,
            render: buySourceSetting,
          },
        ],
      },
      {
        key: "Procurement_plan",
        label: "采购计划",
        path: "/Procurement_plan",
        exact: true,
        render: BuyPlan,
      },
    ],
  },
  {
    key: "other",
    label: "其他业务",
    icon: menuIconUrls[2],
    children: [
      {
        key: "inTransportNotice",
        label: "厂内倒运通知单",
        path: "/inTransportNotice",
        exact: true,
        render: OtherInTransportNotice,
      },
      {
        key: "inTransportPoundList",
        label: "厂内倒运磅单",
        path: "/inTransportPoundList",
        exact: true,
        render: OtherInTransportPoundList,
      },
    ],
  },
  {
    key: "manage",
    label: "业务管理",
    icon: menuIconUrls[3],
    children: [
      {
        key: "businessApproval",
        label: "业务审批",
        children: [
          {
            key: "weightApproval",
            label: "过磅审批",
            path: "/weightApproval",
            exact: true,
            render: weightApproval,
          },
        ],
      },
      {
        key: "financyManager",
        label: "财务管理",
        children: [
          {
            key: "customerBalance",
            label: "客户余额",
            path: "/customerBalance",
            exact: true,
            render: customerBalance,
          },
          {
            key: "financeDetail",
            label: "资金明细",
            path: "/financeDetail",
            exact: true,
            render: financeDetail,
          },
        ],
      },
      {
        key: "order",
        label: "支付订单管理",
        children: [
          {
            key: "payOrder",
            label: "支付订单查询",
            path: "/payOrder",
            exact: true,
            render: PayOrder,
          },
          {
            key: "orderSteam",
            label: "上游流水查询",
            path: "/orderSteam",
            exact: true,
            render: UpstreamFlow,
          },

          {
            key: "Sub_account_flow_query",
            label: "分账流水查询",
            path: "/Sub_account_flow_query",
            exact: true,
            render: SplitAccount,
          },
        ],
      },
      {
        key: "reportFormManage",
        label: "报表管理",
        children: [
          {
            key: "buyReportForm",
            label: "采购报表",
            children: [
              {
                key: "buyDetailEachCar",
                label: "采购逐车明细",
                path: "/buyDetailEachCar",
                exact: true,
                render: buyDetailEachCar,
              },
              {
                key: "buySupplierCount",
                label: "采购供应商统计",
                path: "/buySupplierCount",
                exact: true,
                render: buySupplierCount,
              },
            ],
          },
          {
            key: "saleReportForm",
            label: "销售报表",
            children: [
              {
                key: "saleDetailEachCar",
                label: "销售逐车明细",
                path: "/saleDetailEachCar",
                exact: true,
                render: saleDetailEachCar,
              },
              {
                key: "saleCustomerCount",
                label: "销售客户统计",
                path: "/saleCustomerCount",
                exact: true,
                render: saleCustomerCount,
              },
            ],
          },
          {
            key: "transportReportForm",
            label: "倒运报表",
            children: [
              {
                key: "transportDetailEachCar",
                label: "倒运逐车明细",
                path: "/transportDetailEachCar",
                exact: true,
                render: transportDetailEachCar,
              },
              {
                key: "transportMaterial",
                label: "倒运物料调出堆场统计",
                path: "/transportMaterial",
                exact: true,
                render: transportMaterial,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "file",
    label: "档案管理",
    icon: menuIconUrls[4],
    children: [
      {
        key: "customerFile",
        label: "客户档案",
        path: "/customerFile",
        exact: true,
        render: FileCustomer,
      },
      {
        key: "supplierFile",
        label: "供应商档案",
        path: "/supplierFile",
        exact: true,
        render: FileSupplier,
      },
      {
        key: "carManage",
        label: "车辆管理",
        children: [
          {
            key: "carManageList",
            label: "车辆管理",
            path: "/carManageList",
            exact: true,
            render: FileCarManageList,
          },
          {
            key: "carBlackList",
            label: "车辆黑名单",
            path: "/carBlackList",
            exact: true,
            render: FileCarBlackList,
          },
          {
            key: "carDeduction",
            label: "特殊车辆",
            path: "/carDeduction",
            exact: true,
            render: SpecialVehicle,
          },

        ],
      },
      {
        key: "driverManage",
        label: "司机管理",
        path: "/driverManage",
        exact: true,
        render: FileDriver,
      },
      {
        key: "materialManage",
        label: "物料管理",
        path: "/materialManage",
        exact: true,
        render: FileMaterial,
      },
      {
        key: "yardManage",
        label: "堆场管理",
        path: "/yardManage",
        exact: true,
        render: FileYard,
      },
      {
        key: "customPriceManage",
        label: "客户物料价格管理",
        path: "/customPriceManage",
        exact: true,
        render: CustomPrice,
      },
      {
        key: "saleOverload",
        label: "销售超载管理",
        path: "/saleOverload",
        exact: true,
        render: SaleOverload,
      },
      {
        key: "iot",
        label: "IOT",
        children: [
          {
            key: "production_management",
            label: "产品管理",
            path: "/production_management",
            exact: true,
            render: ProductManage,
          },
          {
            key: "device_management",
            label: "设备管理",
            path: "/device_management",
            exact: true,
            render: EquipmentManage,
          },
        ],
      },
    ],
  },
  {
    key: "setting",
    label: "系统设置",
    icon: menuIconUrls[5],
    children: [
      {
        key: "userManage",
        label: "用户管理",
        path: "/userManage",
        exact: true,
        render: userManage,
      },
      {
        key: "roleManage",
        label: "角色管理",
        path: "/roleManage",
        exact: true,
        render: roleManage,
      },
      {
        key: "systemLog",
        label: "系统日志",
        children: [
          {
            key: "loginLog",
            label: "登录日志",
            path: "/loginLog",
            exact: true,
            render: loginLog,
          },
          {
            key: "replyLog",
            label: "申请单日志",
            path: "/replyLog",
            exact: true,
            render: replyLog,
          },
          {
            key: "noticeLog",
            label: "通知单日志",
            path: "/noticeLog",
            exact: true,
            render: noticeLog,
          },
          // {
          //   key: 'noteLog',
          //   label: '短信日志',
          //   path: '/noteLog',
          //   exact: true,
          //   render: noteLog,
          // },
          {
            key: "otherOptionLog",
            label: "其他操作日志",
            path: "/otherOptionLog",
            exact: true,
            render: otherOptionLog,
          },
          {
            key: "poundLog",
            label: "磅单日志",
            path: "/poundLog",
            exact: true,
            render: poundLog,
          },
        ],
      },
      {
        key: "processSet",
        label: "主流程设置",
        path: "/processSet",
        exact: true,
        render: processSet,
      },
      // {
      //   key: "approvalSet",
      //   label: "审批流程设置",
      //   path: "/approvalSet",
      //   exact: true,
      //   render: ApprovalProcess,
      // },
      // {
      //   key: "addProcess",
      //   label: "添加审批流程",
      //   path: "/addProcess/:type/:id",
      //   exact: true,
      //   render: AddProcess,
      //   isMenu: false,
      // },
    ],
  },
];
