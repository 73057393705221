import BaseButton from "@/components/BaseButton";
import React, { Component } from "react";
import "./index.scss";
function getButtonInner(text) {
  return (
    <div className="buttonInner">
      <i></i>
      <span>{text}</span>
    </div>
  );
}
function getButtonInnerNoIcon(text) {
  return (
    <div className="buttonInnerNoIcon">
      <i></i>
      <span>{text}</span>
    </div>
  );
}
//登录
export class LoginButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-true bfb-loginButton"
        onClick={this.click}
        content="立即登录"
      />
    );
  }
}
//重置
export class ResetButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-false bfb-resetButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "重置")}
      />
    );
  }
}
//查询
export class SearchButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        loading={this.props.loading}
        className="bfb-button bfb-true bfb-searchButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "查询")}
      />
    );
  }
}
//取消
export class CancelButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-false bfb-cancelButton"
        onClick={this.click}
        content={this.props.text || "取消"}
      />
    );
  }
}
//保存
export class SaveButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-true bfb-saveButton"
        onClick={this.click}
        content={this.props.text || "保存"}
      />
    );
  }
}
//确定
export class OkButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        loading={this.props.loading}
        disabled={this.props.disabled}
        className={
          this.props.disabled
            ? "bfb-button-disabled"
            : "bfb-button bfb-true bfb-okButton"
        }
        onClick={this.click}
        content={this.props.text || "确定"}
      />
    );
  }
}
//关闭
export class CloseButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-false bfb-closeButton"
        onClick={this.click}
        content={this.props.text || "关闭"}
      />
    );
  }
}

//操作按钮

//刷新
export class RefreshButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        loading={this.props.loading}
        className="bfb-button bfb-refreshButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "刷新")}
      />
    );
  }
}

//新建通知单
export class AddNoticeButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-addButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "新建通知单")}
      />
    );
  }
}
//新增
export class AddButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-addButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "新增")}
      />
    );
  }
}
//批量新增
export class AddMuchButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-addMuchButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "批量新增")}
      />
    );
  }
}
//新增申请单
export class AddApplyButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-addButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "新增申请单")}
      />
    );
  }
}

//新增派单控制
export class AddDistributeOrderButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-addButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "新增派单控制")}
      />
    );
  }
}

//新增派车控制
export class AddDistributeCarButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-addButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "新增派车控制")}
      />
    );
  }
}

//审核
export class VerifyButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-verifyButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "审核")}
      />
    );
  }
}

//反审
export class ReVerifyButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-reVerifyButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "反审")}
      />
    );
  }
}

//作废
export class DestoryButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-destoryButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "作废")}
      />
    );
  }
}

//强制出厂
export class OutButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-outButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "强制出厂")}
      />
    );
  }
}
//设置为未入厂
export class SettingButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-settingButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "设置为未入厂")}
      />
    );
  }
}
//延期
export class DelayButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-delayButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "延期")}
      />
    );
  }
}
//读卡
export class ReadCardButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-readCardButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "读卡")}
      />
    );
  }
}
//写卡
export class WriteCardButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-writeCardButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "写卡")}
      />
    );
  }
}
//补打磅单
export class PrintButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-printButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "补打磅单")}
      />
    );
  }
}

//磅单增补
export class AddFileButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-addFileButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "磅单补增")}
      />
    );
  }
}
//红冲
export class HongchongButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-hongchongButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "红冲")}
      />
    );
  }
}
//参照
export class ReferenceButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-referenceButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "参照")}
      />
    );
  }
}
//启用
export class UseButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-useButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "启用")}
      />
    );
  }
}
//推单
export class PushOrderButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-pushOrderButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "推单")}
      />
    );
  }
}

//状态更新
export class UpdateStatusButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-updateStatusButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "状态更新")}
      />
    );
  }
}
//厂区审核
export class Verify2Button extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-verify2Button"
        onClick={this.click}
        content={getButtonInner(this.props.text || "厂区审核")}
      />
    );
  }
}

//混样
export class MixButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-mixButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "混样")}
      />
    );
  }
}
//手动推单
export class PushOrderHandleButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-pushOrderHandleButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "手动推单")}
      />
    );
  }
}
//联机修改
export class WriteButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-writeButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "联机修改")}
      />
    );
  }
}
//导出本页
export class ExportButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-exportButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "导出本页")}
      />
    );
  }
}
//导出全部
export class ExportAllButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-exportAllButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "导出全部")}
      />
    );
  }
}

//编辑
export class EditButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-editButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "编辑")}
      />
    );
  }
}
//删除
export class DeleteButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-deleteButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "删除")}
      />
    );
  }
}
//角色授权
export class AddRightButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-addRightButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "角色授权")}
      />
    );
  }
}

//复制
export class CopyButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button  bfb-syncronizeButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "复制")}
      />
    );
  }
}

//同步更新
export class SyncronizeButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-refreshButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "更新")}
      />
    );
  }
}

//补卡
export class ReplacementCardButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-replacementCardButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "补卡")}
      />
    );
  }
}

//拉黑
export class BlackedButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-blackedButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "拉黑")}
      />
    );
  }
}
//重置密码
export class ResetPasswordButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-resetPasswordButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "重置密码")}
      />
    );
  }
}
//安全检测
export class SafeCheckButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-refreshButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "安全检测")}
      />
    );
  }
}
//收货确认
export class ReceiveGoodsButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button w2 bfb-receiveGoodsButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "收货确认")}
      />
    );
  }
}

//二维码
export class QRcodeButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-QRcodeButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "二维码")}
      />
    );
  }
}

//停用
export class UnuseButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-button bfb-unuseButton"
        onClick={this.click}
        content={getButtonInner(this.props.text || "停用")}
      />
    );
  }
}

//安全检测

export class SafeInspectButton extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <BaseButton
        className="bfb-buttonNoIcon bfb-safeinspectbutton"
        onClick={this.click}
        content={getButtonInnerNoIcon(this.props.text || "停用")}
        disabled={this.props.disabled}
        keys={this.props.keys}
      />
    );
  }
}
