export default {
  module: "",
  gate: "web",
  method: "post",
  filters: function () {}, //默认拦截过滤器
  list: {
    user: {
      login: { module: "", url: "/login" }, //用户登录
      chooseFactory: { url: "/change/factory" }, // 切换组织
      changePassword: { url: "/user/changePassword/update" }, //修改密码
      resetPassword: { url: "/user/resetPassword/update" }, // 重置密码
      userRoleDeletes: { url: "/userRole/deletes" }, // 用户删除
      userDeletes: { url: "/user/deletes" }, // 用户删除
      // getOrgByFactoryId: { url: '/org/byFactoryId/query', method: 'GET' }, //根据厂区id查询对应的销售组织
      getOrgByFactoryId: { url: "/org/byFactoryId/pageList", method: "GET" }, //根据厂区id查询对应的销售组织
      getPurchaseOrgFactoryId: {
        url: "/purchaseOrg/byFactoryId/pageList",
        method: "GET",
      }, //根据厂区id查询对应的采购组织
    },
    home: {
      vehicleInFactoryCountQuery: {
        url: "/vehicle/vehicleInFactoryCount/query",
        method: "get",
      }, // 在厂车辆统计查询
      purchasePoundDailyList: {
        url: "/purchasePoundDaily/list",
        method: "get",
      }, // 日采购额统计
      businessReviewCountQuery: {
        url: "/businessReview/BusinessReviewCount/query",
        method: "get",
      }, // 业务审批统计
      salePoundMaterialCountTodayQuery: {
        url: "/salePoundMaterialCount/today/query",
        method: "get",
      }, // 日销售购额统计

      purchaseNoticeVehicleCountQuery: {
        url: "/purchaseNotice/vehicleCount/query",
        method: "get",
      }, // 采购车辆统计
      saleNoticeVehicleCountQuery: {
        url: "/saleNotice/vehicleCount/query",
        method: "get",
      }, // 销售车辆统计
      salePoundMaterialCountByDayQuery: {
        url: "/salePoundMaterialCount/byDay/query",
        method: "get",
      }, // 销售走势统计
    },
    sale: {
      //销售申请单
      saleApplyList: { url: "/saleApply/pageList", method: "GET" }, //查询
      saleApplyUpdate: { url: "/saleApply/update" }, //修改
      saleApplyDelete: { url: "/saleApply/deletes" }, //删除
      saleApplyAdd: { url: "/saleApply/add" }, //添加
      saleApplyDestory: { url: "/saleApply/invalid/update" }, //作废
      saleApplyVerify: { url: "/saleApply/review/update" }, //审核
      saleApplyDetail: { url: "/saleApply/get", method: "GET" }, //详情查询
      saleApplyPush: { url: "/saleApply/push/update" }, //推单
      saleApplyClose: { url: "/saleApply/close" }, //关闭
      saleApplyOpen: { url: "/saleApply/open" }, //打开

      //到货通知单
      saleNoticeList: { url: "/saleNotice/pageList", method: "GET" }, //查询
      saleNoticeUpdate: { url: "/saleNotice/update" }, //修改
      saleNoticeDelete: { url: "/saleNotice/deletes" }, //删除
      saleNoticeAdd: { url: "/saleNotice/add" }, //添加
      saleNoticeDestory: { url: "/saleNotice/invalid/update" }, //作废
      saleNoticeVerify: { url: "/saleNotice/review/update" }, //审核
      saleNoticeDelay: { url: "/saleNotice/delay/update" }, //延期
      saleNoticeForceOut: { url: "/saleNotice/forceOut/update" }, //强制出厂
      saleNoticeSetNotIn: { url: "/saleNotice/setNotIn/update" }, //设置未入场
      saleNoticeDetail: { url: "/saleNotice/get", method: "GET" }, //详情查询
      getPoundByNoticeCode: {
        url: "/salePound/byNoticeCode/query",
        method: "GET",
      }, //根据通知单code查询磅单
      //销售磅单
      salePoundList: { url: "/salePound/pageList", method: "GET" }, //查询
      salePoundUpdate: { url: "/salePound/update" }, //修改
      salePoundDelete: { url: "/salePound/deletes" }, //删除
      salePoundAdd: { url: "/salePound/add" }, //添加
      salePoundSupply: { url: "/salePound/supple/update" }, //补增
      salePoundRefer: { url: "/salePound/refer/update" }, //参照
      salePoundDestory: { url: "/salePound/invalid/update" }, //作废
      salePoundPushStatus: { url: "/salePound/push/update" }, //推单
      salePoundReverse: { url: "/salePound/reverse/update" }, //红冲
      salePoundDetail: { url: "/salePound/get", method: "GET" }, //详情查询
      //批号
      saleBatchList: { url: "/saleBatch/pageList", method: "GET" }, //查询
      saleBatchUpdate: { url: "/saleBatch/update" }, //修改
      saleBatchDelete: { url: "/saleBatch/deletes" }, //删除
      saleBatchAdd: { url: "/saleBatch/add" }, //添加
      saleBatchVerify: { url: "/saleBatch/review/update" }, //审核
      saleBatchDetail: { url: "/saleBatch/get" }, //详情查询
      //其他销售申请单
      saleOtherApplyList: { url: "/saleOtherApply/pageList", method: "GET" }, //查询
      saleOtherApplyUpdate: { url: "/saleOtherApply/update" }, //修改
      saleOtherApplyDelete: { url: "/saleOtherApply/deletes" }, //删除
      saleOtherApplyAdd: { url: "/saleOtherApply/add" }, //添加
      saleOtherApplyDestory: { url: "/saleOtherApply/invalid/update" }, //作废
      saleOtherApplyVerify: { url: "/saleOtherApply/review/update" }, //审核
      saleOtherApplyDetail: { url: "/saleOtherApply/get", method: "GET" }, //详情查询
      //其他出库通知单
      saleOtherNoticeList: { url: "/saleOtherNotice/pageList", method: "GET" }, //查询
      saleOtherNoticeUpdate: { url: "/saleOtherNotice/update" }, //修改
      saleOtherNoticeDelete: { url: "/saleOtherNotice/deletes" }, //删除
      saleOtherNoticeAdd: { url: "/saleOtherNotice/add" }, //添加
      saleOtherNoticeVerify: { url: "/saleOtherNotice/review/update" }, //审核
      saleOtherNoticeDestory: { url: "/saleOtherNotice/invalid/update" }, //作废
      saleOtherNoticeDetail: { url: "/saleNotice/get", method: "GET" }, //详情查询
      getOtherPoundByNoticeCode: {
        url: "/saleOtherPound/byNoticeCode/query",
        method: "GET",
      }, //根据通知单code查询磅单
      //其他销售磅单
      saleOtherPoundList: { url: "/saleOtherPound/pageList", method: "GET" }, //查询
      saleOtherPoundUpdate: { url: "/saleOtherPound/update" }, //修改
      saleOtherPoundDelete: { url: "/saleOtherPound/deletes" }, //删除
      saleOtherPoundAdd: { url: "/saleOtherPound/add" }, //添加
      saleOtherPoundSupple: { url: "/saleOtherPound/supple/update" }, //补增
      saleOtherPoundPrint: { url: "/saleOtherPound/print/update" }, //补打
      saleOtherPoundDestory: { url: "/saleOtherPound/invalid/update" }, //作废
      saleOtherPoundDetail: { url: "/saleOtherPound/get" }, //详情查询

      //销售磅单补增
      saleSupplementUpdate: { url: "/salePound/supplement/update" },
      //是否与允许异常补增
      isExceptionAdd: { url: "/salePound/isExceptionAdd/query", method: "GET" },
      //异常补增
      salePoundExceptionAdd: { url: "/salePound/exceptionAdd/update" },
      //销售计划
      salePlanPageList: { url: "/salePlan/pageList", method: "GET" }, //查询
      salePlanPageAdd: { url: "/salePlan/add" }, //添加
      salePlanEnable: { url: "/salePlan/enable" }, //启用
      salePlanUnEnable: { url: "/salePlan/unEnable" }, //禁用
      salePlanReview: { url: "/salePlan/review" }, //审核
      salePlanDeletes: { url: "/salePlan/deletes" }, //删除
      salePlanFinish: { url: "/salePlan/finish" }, //完成
      //分销
      saleDistributeCheck: { url: "/saleApply/distribute/check" }, //销售申请单分销检查
      saleDistribute: { url: "/saleApply/distribute" }, //销售申请单分销
      getSaleDistribute: { url: "/saleApply/distribute/get" }, //销售申请单分销查询
      invalidsaleDistribute: { url: "/saleApply/distribute/invalid" }, //销售申请单分销取消

      //销售合同
      saleContractList: { url: "/saleContract/pageList", method: "GET" }, //查询
      saleContractAdd: { url: "/saleContract/add" }, //添加
      saleContractUpdate: { url: "/saleContract/update" }, //编辑
      saleContractDetail: { url: "/saleContract/get", method: "GET" }, //详情查询
      saleContractApproval: { url: "/saleContract/approval" }, //审批
      saleContractStop: { url: "/saleContract/stop" }, //终止
      saleContractEnable: { url: "/saleContract/enable" }, //启用
      saleContractReject: { url: "/saleContract/reject" }, //驳回
      //调价协议
      saleAdjustList: { url: "/saleAdjust/pageList", method: "GET" }, //查询
      //协议查询销售合同
      saleAdjustAdjustList: {
        url: "/saleContract/adjust/pageList",
        method: "GET",
      },
      //添加销售调价协议
      saleAdjustAdd: {
        url: "/saleAdjust/add",
      },
      //销售调价协议审批
      saleAdjustApproval: {
        url: "/saleAdjust/approval",
      },
      //销售调价协议作废
      saleAdjustInvalid: {
        url: "/saleAdjust/invalid",
      },
      //销售调价详情查询
      saleAdjustDetail: { url: "/saleAdjust/get", method: "GET" },
    },
    buy: {
      //采购申请单
      purchaseApplyList: { url: "/purchaseApply/pageList", method: "GET" }, //查询
      purchaseApplyDelete: { url: "/purchaseApply/delete" }, //删除
      purchaseApplyAdd: { url: "/purchaseApply/add" }, //添加
      review: { url: "/purchaseApply/review/update" }, //审核
      purchaseApplyDestory: { url: "/purchaseApply/invalid/update" }, //作废
      purchaseApplyDetail: { url: "/purchaseApply/get", method: "GET" }, //详情查询
      purchaseApplyClose: { url: "/purchaseApply/close" }, //关闭
      purchaseApplyOpen: { url: "/purchaseApply/open" }, //打开
      // 采购通知单
      buyNoticeList: { url: "/purchaseNotice/pageList", method: "GET" },
      buyNoticeDetail: { url: "/purchaseNotice/get", method: "GET" },
      AddBuyNotice: { url: "/purchaseNotice/add" },
      updataBuyNotice: { url: "/purchaseNotice/update" },
      // 其他采购通知单收货确认
      purchaseOtherNoticeConfirmUpdate: {
        url: "/purchaseOtherNotice/receiveConfirm/update",
      },
      // 其他采购通知单作废
      purchaseOtherNoticeOperationUpdate: {
        url: "/purchaseOtherNotice/operation/update",
      },
      auditBuyNotice: { url: "/purchaseNotice/audit/update" }, // 审核，反审
      forceOutBuyNotice: { url: "/purchaseNotice/forceOutFactory/update" }, // 通知单强制出厂
      setNotInBuyNotice: { url: "/purchaseNotice/setNotInFactory/update" }, // 设置未入场
      invalidBuyNotice: { url: "/purchaseNotice/invalid/update" }, // 通知单作废
      delayBuyNotice: { url: "/purchaseNotice/delay/update" }, // 通知单延期

      //采购磅单
      purchasePoundList: { url: "/purchasePound/pageList", method: "GET" }, //查询
      purchasePoundUpdate: { url: "/purchasePound/update" }, //修改
      purchasePoundDelete: { url: "/purchasePound/deletes" }, //删除
      purchasePoundAdd: { url: "/purchasePound/add" }, //添加
      purchasePoundSupply: { url: "/purchasePound/supplement/update" }, //补增
      purchasePoundDetail: { url: "/purchasePound/get", method: "GET" }, //详情查询
      purchasePoundReverseUpdate: { url: "/purchasePound/reverse/update" }, //采购磅单红冲
      purchasePoundRefer: { url: "/purchasePound/refer/update" }, //采购磅单参照
      purchasePoundPush: { url: "/purchasePound/push/update" }, //采购磅单推单
      purchasePoundInvalid: { url: "/purchasePound/invalid/update" }, //采购磅单作废
      //是否与允许异常补增
      isExceptionAdd: {
        url: "/purchasePound/isExceptionAdd/query",
        method: "GET",
      },
      //异常补增
      purchasePoundExceptionAdd: { url: "/purchasePound/exceptionAdd/update" },

      // 厂内倒运通知单
      insideNoticeList: { url: "/insideNotice/pageList", method: "GET" }, //查询
      insideNoticeUpdate: { url: "/insideNotice/update" }, //修改
      insideNoticeDelete: { url: "/insideNotice/deletes" }, //删除
      insideNoticeAdd: { url: "/insideNotice/add" }, //添加
      insideNoticeDetail: { url: "/insideNotice/get", method: "GET" }, //详情查询
      insideNoticeInvalidUpdate: { url: "/insideNotice/invalid/update" }, //作废
      insideNoticeReviewDetail: { url: "/insideNotice/review/update" }, //审核
      insideNoticeUnreviewDetail: { url: "/insideNotice/unreview/update" }, //反审

      // 成品库
      inventoryList: { url: "/inventory/pageList", method: "GET" }, //查询
      inventoryUpdate: { url: "/inventory/update" }, //修改
      inventoryDelete: { url: "/inventory/deletes" }, //删除
      inventoryAdd: { url: "/inventory/add" }, //添加
      inventoryDetail: { url: "/inventory/get", method: "GET" }, //详情查询
      //IC卡
      icCardList: { url: "/icCard/pageList", method: "GET" }, //查询
      icCardUpdate: { url: "/icCard/update" }, //修改
      icCardDelete: { url: "/icCard/deletes" }, //删除
      icCardAdd: { url: "/icCard/add" }, //添加
      icCardDetail: { url: "/icCard/get", method: "GET" }, //详情查询
      // 采购原发设置
      miningOriginalSetList: {
        url: "/miningOriginalSet/pageList",
        method: "GET",
      }, //查询
      miningOriginalSetUpdate: { url: "/miningOriginalSet/update" }, //修改
      miningOriginalSetDelete: { url: "/miningOriginalSet/deletes" }, //删除
      miningOriginalSetAdd: { url: "/miningOriginalSet/add" }, //添加
      miningOriginalSetDetail: { url: "/miningOriginalSet/get", method: "GET" }, //详情查询
      // 采购采样
      purchaseSamplingList: {
        url: "/purchaseSampling/pageList",
        method: "GET",
      }, //查询
      purchaseSamplingUpdate: { url: "/purchaseSampling/update" }, //修改
      purchaseSamplingDelete: { url: "/purchaseSampling/deletes" }, //删除
      purchaseSamplingAdd: { url: "/purchaseSampling/add" }, //添加
      purchaseSamplingDetail: { url: "/purchaseSampling/get", method: "GET" }, //详情查询
      purchaseSamplingClearUpdate: { url: "/purchaseSampling/clear/update" }, //采样卡信息清除

      // 采购化验
      purchaseSampleAssayList: {
        url: "/purchaseSampleAssay/pageList",
        method: "GET",
      }, //查询
      purchaseSampleAssayDelete: { url: "/purchaseSampleAssay/deletes" }, //删除
      purchaseSampleAssayDetail: {
        url: "/purchaseSampleAssay/get",
        method: "GET",
      }, //详情查询
      // 其他入库申请单
      purchaseOtherApplyList: {
        url: "/purchaseOtherApply/pageList",
        method: "GET",
      }, //查询
      purchaseOtherApplyUpdate: { url: "/purchaseOtherApply/update" }, //修改
      purchaseOtherApplyDelete: { url: "/purchaseOtherApply/deletes" }, //删除
      purchaseOtherApplyAdd: { url: "/purchaseOtherApply/add" }, //添加
      purchaseOtherApplyDetail: {
        url: "/purchaseOtherApply/get",
        method: "GET",
      }, //详情查询
      // 其他采购申请单审核，反审，作废
      purchaseOtherApplyOperationUpdate: {
        url: "/purchaseOtherApply/operation/update",
      },
      // 其他入库通知单
      purchaseOtherNoticeList: {
        url: "/purchaseOtherNotice/pageList",
        method: "GET",
      }, //查询
      purchaseOtherNoticeUpdate: { url: "/purchaseOtherNotice/update" }, //修改
      purchaseOtherNoticeDelete: { url: "/purchaseOtherNotice/deletes" }, //删除
      purchaseOtherNoticeAdd: { url: "/purchaseOtherNotice/add" }, //添加
      purchaseOtherNoticeDetail: {
        url: "/purchaseOtherNotice/get",
        method: "GET",
      }, //详情查询
      // 其他入库磅单
      purchaseOtherPoundList: {
        url: "/purchaseOtherPound/pageList",
        method: "GET",
      }, //查询
      purchaseOtherPoundUpdate: { url: "/purchaseOtherPound/update" }, //修改
      purchaseOtherPoundDelete: { url: "/purchaseOtherPound/deletes" }, //删除
      purchaseOtherPoundAdd: { url: "/purchaseOtherPound/add" }, //添加
      purchaseOtherPoundDetail: {
        url: "/purchaseOtherPound/get",
        method: "GET",
      }, //详情查询
      // 采购原发设置
      purchaseOriginalSetList: {
        url: "/purchaseOriginalSet/pageList",
        method: "GET",
      }, //查询
      purchaseOriginalSetUpdate: { url: "/purchaseOriginalSet/update" }, //修改
      purchaseOriginalSetDelete: { url: "/purchaseOriginalSet/delete" }, //删除
      purchaseOriginalSetAdd: { url: "/purchaseOriginalSet/add" }, //添加
      purchaseOriginalSetDetail: {
        url: "/purchaseOriginalSet/get",
        method: "GET",
      }, //详情查询
      // 采矿点原发设置
      mineSiteOriginalSetList: {
        url: "/mineSiteOriginalSet/pageList",
        method: "GET",
      }, //查询
      mineSiteOriginalSetUpdate: { url: "/mineSiteOriginalSet/update" }, //修改
      mineSiteOriginalSetDelete: { url: "/mineSiteOriginalSet/delete" }, //删除
      mineSiteOriginalSetAdd: { url: "/mineSiteOriginalSet/add" }, //添加
      mineSiteOriginalSetDetail: {
        url: "/mineSiteOriginalSet/get",
        method: "GET",
      }, //详情查询
      // 采购抽检设置
      purchaseCheckSetList: {
        url: "/purchaseCheckSet/pageList",
        method: "GET",
      }, //查询
      purchaseCheckSetUpdate: { url: "/purchaseCheckSet/update" }, //修改
      purchaseCheckSetDelete: { url: "/purchaseCheckSet/deletes" }, //删除
      purchaseCheckSetAdd: { url: "/purchaseCheckSet/add" }, //添加
      purchaseCheckSetDetail: { url: "/purchaseCheckSet/get", method: "GET" }, //详情查询
      // 过磅审批
      poundReviewList: { url: "/poundReview/pageList", method: "GET" }, //查询
      poundReviewUpdate: { url: "/poundReview/update" }, //修改
      poundReviewDelete: { url: "/poundReview/deletes" }, //删除
      poundReviewAdd: { url: "/poundReview/add" }, //添加
      poundReviewDetail: { url: "/poundReview/get", method: "GET" }, //详情查询

      //采购计划
      purchasePlanPageList: { url: "/purchasePlan/pageList", method: "GET" }, //查询
      purchasePlanPageAdd: { url: "/purchasePlan/add" }, //添加
      purchasePlanEnable: { url: "/purchasePlan/enable" }, //启用
      purchasePlanUnEnable: { url: "/purchasePlan/unEnable" }, //禁用
      purchasePlanReview: { url: "/purchasePlan/review" }, //审核
      purchasePlanDeletes: { url: "/purchasePlan/deletes" }, //删除
      purchasePlanFinish: { url: "/purchasePlan/finish" }, //完成

      //分销
      purchaseDistributeCheck: { url: "/purchaseApply/distribute/check" }, //采购申请单分销检查
      purchaseDistribute: { url: "/purchaseApply/distribute" }, //采购申请单分销
      getPurchaseDistribute: { url: "/purchaseApply/distribute/get" }, //采购申请单分销查询
      invalidPurchaseDistribute: { url: "/purchaseApply/distribute/invalid" }, //采购申请单分销取消
    },
    system: {
      // 客户管理
      customerList: { url: "/customer/pageList", method: "GET" }, //查询
      customerUpdate: { url: "/customer/update" }, //修改
      customerDelete: { url: "/customer/deletes" }, //删除
      customerAdd: { url: "/customer/add" }, //添加
      customerDetail: { url: "/customer/get", method: "GET" }, //详情查询
      // 已授权应用菜单及功能
      permAuthedMenuFunctionsQuery: {
        url: "/perm/authedMenuFunctions/query",
        method: "GET",
      },
      // 角色授权
      roleAuthUpdate: { url: "/role/auth/update" },
      // 获取用户子角色列表
      // roleList:  { url: '/role/list',method:'GET'  },
      // 用户管理
      innerUserList: { url: "/user/pageList", method: "GET" },
      innerUserAdd: { url: "/user/add" },
      innerUserUpdate: { url: "/user/update" },
      innerUserDelete: { url: "/user/deletes" },
      innerUserQuery: { url: "/user/get", method: "GET" },
      // 用户分页查询
      userList: { url: "/customerOrSupplier/pageList", method: "GET" },
      userUpdate: { url: "/customerOrSupplier/update" },

      // 获取用户子角色列表
      permSubRolesQuery: { url: "/perm/subRoles/query", method: "GET" },
      roleList: { url: "/role/list", method: "GET" },
      roleAdd: { url: "/role/add" },
      roleUpdate: { url: "/role/update" },
      roleDelete: { url: "/role/deletes" },
      // 角色下的用户
      roleUserList: { url: "/roleUser/list", method: "GET" },
      // 日志
      systemLog: { url: "/log/pageList", method: "GET" },

      //审批流
      saleApprovalAdd: { url: "/saleApproval/add" }, //添加审批流
      saleApprovalDelete: { url: "/saleApproval/deletes" }, //删除审批流
      userPageList: { url: "/saleApproval/getUserList", method: "GET" }, //用户查询
      saleApprovalPageList: { url: "/saleApproval/pageList", method: "GET" }, //查询审批流
      saleApprovalUpdate: { url: "/saleApproval/update" }, //修改审批流
      saleApprovalGet: { url: "/saleApproval/get", method: "GET" }, //销售审批流详情查询
      sysSetGetOne: { url: "/sysSet/getOne", method: "GET" }, //查询开关设置
    },
    //业务管理
    business: {
      /*----业务审批-----*/
      //入场审批
      admissionApprovalList: { url: "/enterReview/pageList", method: "GET" },
      admissionApprovalUpdate: { url: "/enterReview/review/update" },
      //过磅审批
      poundReviewList: { url: "/poundReview/pageList", method: "GET" },
      poundReviewUpdate: { url: "/poundReview/review/update" },
      /*-----财务管理-----*/
      //客户余额
      customerBalanceList: { url: "/customerBalance/pageList", method: "GET" }, //查询
      customerBalanceUpdate: { url: "/customerBalance/update" }, //修改
      customerBalanceDelete: { url: "/customerBalance/deletes" }, //删除
      customerBalanceAdd: { url: "/customerBalance/add" }, //添加
      customerBalanceDetail: { url: "/customerBalance/get", method: "GET" }, //详情查询
      //资金明细
      customerStatementList: {
        url: "/customerStatement/pageList",
        method: "GET",
      }, //查询
      customerStatementUpdate: { url: "/customerStatement/update" }, //修改
      customerStatementDelete: { url: "/customerStatement/deletes" }, //删除
      customerStatementAdd: { url: "/customerStatement/add" }, //添加
      customerStatementDetail: { url: "/customerStatement/get", method: "GET" }, //详情查询
      exportExcelCustomerStatement: {
        url: "/export/excel/customerStatement",
        method: "GET",
        responseType: "blob",
      }, //流水导出

      /*-----报表管理-----*/
      // 采购报表
      //采购报表逐车明细
      purchaseCarReport: {
        url: "/purchasePoundReport/pageList",
        method: "GET",
      },
      purchaseCarReportAll: {
        url: "/purchasePoundReport/statistics/query",
        method: "GET",
      },
      purchaseCarReportExport: {
        url: "/export/excel/purchasePoundReport",
        method: "GET",
        responseType: "blob",
      },
      purchasePoundReport: {
        url: "/purchasePoundReport/supplierList",
        method: "GET",
      },
      purchasePoundReportAll: {
        url: "/purchasePoundReport/supplierStatistics/query",
        method: "GET",
      },
      purchasePoundReportExport: {
        url: "/export/excel/purchasePoundSupplierReport",
        method: "GET",
        responseType: "blob",
      },
      //采矿点统计
      purchaseMiningPointReport: {
        url: "/purchasePoundMiningPointReport/pageList",
        method: "GET",
      },
      purchaseMiningPointReportAll: {
        url: "/purchasePoundMiningPointReport/miningPointStatistics/query",
        method: "GET",
      },
      purchaseMiningPointReportExport: {
        url: "/export/excel/purchasePoundMiningPointReport",
        method: "GET",
      },
      //单位统计列表
      purchaseUnitsReport: {
        url: "/purchasePoundUnitReport/pageList",
        method: "GET",
      },
      purchaseUnitsReportAll: {
        url: "/purchasePoundUnitReport/unitStatistics/query",
        method: "GET",
      },
      purchaseUnitsReportExport: {
        url: "/export/excel/purchasePoundUnitReport",
        method: "GET",
      },
      //物料统计
      purchaseMaterialReport: {
        url: "/purchasePoundMaterialReport/pageList",
        method: "GET",
      },
      purchaseMaterialReportAll: {
        url: "/purchasePoundMaterialReport/materialStatistics/query",
        method: "GET",
      },
      purchaseMaterialReportExport: {
        url: "/export/excel/purchasePoundMaterialReport",
        method: "GET",
      },
      //矿口统计
      purchaseMineSiteReport: {
        url: "/purchasePoundMineSiteReport/pageList",
        method: "GET",
      },
      purchaseMineSiteReportAll: {
        url: "/purchasePoundMineSiteReport/mineSiteStatistics/query",
        method: "GET",
      },
      purchaseMineSiteReportExport: {
        url: "/export/excel/purchasePoundMineSiteReport",
        method: "GET",
      },

      // 销售报表
      //销售报表单位
      saleUnitReport: { url: "/salePoundUnitReport/pageList", method: "GET" },
      saleUnitReportAll: {
        url: "/salePoundUnitReport/unitStatistics/query",
        method: "GET",
      },
      saleUnitReportExport: {
        url: "/export/excel/salePoundUnitReport",
        method: "GET",
      },
      saleCustomerList: { url: "/salePoundUnitReport/pageList", method: "GET" },
      saleCustomerAll: {
        url: "/salePoundUnitReport/statistics/query",
        method: "GET",
      },
      saleCustomerExport: {
        url: "/export/excel/salePoundUnitReport",
        method: "GET",
        responseType: "blob",
      },
      //销售报表物料
      saleMaterialReport: {
        url: "/salePoundMaterialReport/pageList",
        method: "GET",
      },
      saleMaterialReportAll: {
        url: "/salePoundMaterialReport/materialStatistics/query",
        method: "GET",
      },
      saleMaterialReportExport: {
        url: "/export/excel/salePoundMaterialReport",
        method: "GET",
      },
      //销售报表逐车明细
      saleCarReport: { url: "/salePoundReport/pageList", method: "GET" },
      saleCarReportAll: {
        url: "/salePoundReport/statistics/query",
        method: "GET",
      },
      saleCarReportExport: {
        url: "/export/excel/salePoundReport",
        method: "GET",
        responseType: "blob",
      },

      // 倒运报表
      //倒运逐车明细
      insidePoundReport: { url: "/insidePound/pageList", method: "GET" },
      insidePoundReportAll: {
        url: "/insidePoundReport/statistics/query",
        method: "GET",
      },
      insidePoundReportExport: {
        url: "/export/excel/insidePoundReport",
        method: "GET",
        responseType: "blob",
      },
      //调入场堆
      insidePoundAllotReport: {
        url: "/insidePoundAllotMaterReport/pageList",
        method: "GET",
      },
      insidePoundAllotReportAll: {
        url: "/insidePoundAllotMaterReport/statistics/query",
        method: "GET",
      },
      insidePoundAllotReportExport: {
        url: "/export/excel/insidePoundAllotMaterReport",
        method: "GET",
      },
      //调出场堆
      outsidePoundAllotReport: {
        url: "/insidePoundAllotMaterOutReport/pageList",
        method: "GET",
      },
      outsidePoundAllotReportAll: {
        url: "/insidePoundAllotMaterOutReport/statistics/query",
        method: "GET",
      },
      outsidePoundAllotReportExport: {
        url: "/export/excel/insidePoundAllotMaterOutReport",
        method: "GET",
        responseType: "blob",
      },
      //物料车号
      materialVehicleReport: {
        url: "/insideMaterialVehicleReport/pageList",
        method: "GET",
      },
      materialVehicleReportAll: {
        url: "/insideMaterialVehicleReport/statistics/query",
        method: "GET",
      },
      materialVehicleReportExport: {
        url: "/export/excel/insideMaterialVehicleReport",
        method: "GET",
      },
      //调拨调入调出
      insideOutReport: {
        url: "/insideAllotMaterDrDcReport/pageList",
        method: "GET",
      },
      insideOutReportAll: {
        url: "/insideAllotMaterDrDcReport/statistics/query",
        method: "GET",
      },
      insideOutReportExport: {
        url: "/export/excel/insideAllotMaterDrDcReport",
        method: "GET",
      },

      // 其他出入库报表
      //入库逐车
      OtherCarInsideReport: {
        url: "/purchaseOtherPound/pageList",
        method: "GET",
      },
      OtherCarInsideReportAll: {
        url: "/purchaseOtherPoundReport/statistics/query",
        method: "GET",
      },
      OtherCarInsideReportExport: {
        url: "/export/excel/purchaseOtherPoundReport",
        method: "GET",
      },
      //入库物料
      OtherMaInsideReport: {
        url: "/purchaseOtherMaterialPoundReport/pageList",
        method: "GET",
      },
      OtherMaInsideReportAll: {
        url: "/purchaseOtherMaterialPoundReport/statistics/query",
        method: "GET",
      },
      OtherMaInsideReportExport: {
        url: "/export/excel/purchaseOtherMaterialPoundReport",
        method: "GET",
      },
      //入库单位
      OtherUnInsideReport: {
        url: "/purchaseOtherUnitPoundReport/pageList",
        method: "GET",
      },
      OtherUnInsideReportAll: {
        url: "/purchaseOtherUnitPoundReport/statistics/query",
        method: "GET",
      },
      OtherUnInsideReportExport: {
        url: "/export/excel/purchaseOtherUnitPoundReport",
        method: "GET",
      },
      //出库逐车
      OtherCarOutsideReport: { url: "/saleOtherPound/pageList", method: "GET" },
      OtherCarOutsideReportAll: {
        url: "/saleOtherPoundReport/statistics/query",
        method: "GET",
      },
      OtherCarOutsideReportExport: {
        url: "/export/excel/saleOtherPoundReport",
        method: "GET",
      },
      //出库物料
      OtherMaOutsideReport: {
        url: "/saleOtherMaterialPoundReport/pageList",
        method: "GET",
      },
      OtherMaOutsideReportAll: {
        url: "/saleOtherMaterialPoundReport/statistics/query",
        method: "GET",
      },
      OtherMaOutsideReportExport: {
        url: "/export/excel/saleOtherMaterialPoundReport",
        method: "GET",
      },
      //出库单位
      OtherUnOutsideReport: {
        url: "/saleOtherUnitPoundReport/pageList",
        method: "GET",
      },
      OtherUnOutsideReportAll: {
        url: "/saleOtherUnitPoundReport/statistics/query",
        method: "GET",
      },
      OtherUnOutsideReportExport: {
        url: "/export/excel/saleOtherUnitPoundReport",
        method: "GET",
      },

      // 矿山报表
      //车辆统计
      MinePoundReport: { url: "/minePoundReport/pageList", method: "GET" },
      MinePoundReportAll: {
        url: "/minePoundReport/vehicleStatistics/query",
        method: "GET",
      },
      MinePoundReportExport: {
        url: "/export/excel/minePoundReport",
        method: "GET",
      },
      // 逐车明细
      MinePoundCarReport: { url: "/minePound/pageList", method: "GET" },
      MinePoundCarReportAll: {
        url: "/minePoundReport/statistics/query",
        method: "GET",
      },
      MinePoundCarReportExport: {
        url: "/export/excel/minePound",
        method: "GET",
      },
      // 派单控制
      orderControlList: { url: "/sendOrderControl/pageList", method: "GET" }, //查询
      orderControlUpdate: { url: "/sendOrderControl/update" }, //修改
      orderControlDelete: { url: "/sendOrderControl/deletes" }, //删除
      orderControlAdd: { url: "/sendOrderControl/add" }, //添加
      orderControlDetail: { url: "/sendOrderControl/get", method: "GET" }, //详情查询
      orderControlUse: { url: "/sendOrderControl/enable/update" }, //启用/禁用
      // 在场车辆控制
      vehicleControlList: { url: "/vehicleControl/pageList", method: "GET" }, //查询
      vehicleControlUpdate: { url: "/vehicleControl/update" }, //修改
      vehicleControlDelete: { url: "/vehicleControl/deletes" }, //删除
      vehicleControlAdd: { url: "/vehicleControl/add" }, //添加
      vehicleControlDetail: { url: "/vehicleControl/get", method: "GET" }, //详情查询
      vehicleControlUse: { url: "/vehicleControl/enable/update" }, //启用/禁用

      //  支付订单
      payOrderList: { url: "/payment/order/pageList", method: "Get" }, //查询
      payFlowList: { url: "/payment/flow/pageList", method: "Get" }, //上游流水查询
      splitFlowList: { url: "/payment/split/flow/pageList", method: "Get" }, //上游流水查询
      flowFeeStatistics: {
        url: "/payment/split/flow/fee_statistics",
        method: "Get",
      }, //分账流水金额汇总
      flowAgainSplit: {
        url: "/payment/split/flow/againSplit",
        method: "Get",
      }, //重新发起
    },
    other: {
      // 厂内倒运磅单
      insidePoundList: { url: "/insidePound/pageList", method: "GET" }, //查询
      insidePoundUpdate: { url: "/insidePound/update" }, //修改
      insidePoundInvalidUpdate: { url: "/insidePound/invalid/update" }, //作废
      insidePoundDelete: { url: "/insidePound/deletes" }, //删除
      insidePoundAdd: { url: "/insidePound/add" }, //添加
      insidePoundDetail: { url: "/insidePound/get", method: "GET" }, //详情查询
      // 矿山磅单
      minePoundList: { url: "/minePound/pageList", method: "GET" }, //查询
      minePoundUpdate: { url: "/minePound/update" }, //修改
      minePoundInvalidUpdate: { url: "/minePound/invalid/update" }, //作废
      minePoundDelete: { url: "/minePound/deletes" }, //删除
      minePoundAdd: { url: "/minePound/add" }, //添加
      minePoundDetail: { url: "/minePound/get", method: "GET" }, //详情查询
      // 矿口管理-采购申请单
      minesiteList: { url: "/minesite/pageList", method: "GET" }, //查询
      minesiteUpdate: { url: "/minesite/update" }, //修改
      minesiteDelete: { url: "/minesite/deletes" }, //删除
      minesiteAdd: { url: "/minesite/add" }, //添加
      minesiteDetail: { url: "/minesite/get", method: "GET" }, //详情查询
    },
    doc: {
      //客户档案
      customerList: { url: "/customer/pageList", method: "GET" }, //查询
      customerUpdate: { url: "/customer/update" }, //修改
      customerDelete: { url: "/customer/deletes" }, //删除
      customerAdd: { url: "/customer/add" }, //添加
      customerDetail: { url: "/customer/get", method: "GET" }, //详情查询
      //供应商档案
      supplierList: { url: "/supplier/pageList", method: "GET" }, //查询
      supplierUpdate: { url: "/supplier/update" }, //修改
      supplierDelete: { url: "/supplier/deletes" }, //删除
      supplierAdd: { url: "/supplier/add" }, //添加
      supplierDetail: { url: "/supplier/get", method: "GET" }, //详情查询
      //客户物料价格
      customPrice: { url: "/customerMaterialPrice/pageList", method: "GET" }, //查询
      customPriceAdd: { url: "/customerMaterialPrice/add", method: "Post" }, //新增
      customPriceEdit: { url: "/customerMaterialPrice/update", method: "Post" }, //编辑
      customPriceDel: { url: "/customerMaterialPrice/delete", method: "Post" }, //删除
      //车辆管理
      vehicleList: { url: "/vehicle/pageList", method: "GET" }, //查询
      vehicleUpdate: { url: "/vehicle/update" }, //修改
      vehicleDelete: { url: "/vehicle/deletes" }, //删除
      vehicleAdd: { url: "/vehicle/add" }, //添加
      vehicleDetail: { url: "/vehicle/get", method: "GET" }, //详情查询
      vehicleSearch: { url: "/vehicle/list", method: "GET" }, //搜索
      //车辆黑名单
      blackVehicleList: { url: "/vehicleBlacklist/pageList", method: "GET" }, //查询
      // blackVehicleUpdate: { url: '/vehicleBlacklist/update' }, //修改
      blackVehicleDelete: { url: "/vehicleBlacklist/deletes" }, //删除
      blackVehicleAdd: { url: "/vehicleBlacklist/add" }, //添加
      // blackVehicleDetail: { url: '/vehicleBlacklist/get',method:'GET' }, //详情查询
      //特殊车辆
      vehicleListOrg: { url: "/vehicle/pageListOrg", method: "GET" }, //查询
      vehicleLsDelete: { url: "/vehicle/ls/delete" }, //删除
      vehicleLsAdd: { url: "/vehicle/ls/add" }, //添加
      //司机管理
      driverList: { url: "/driver/pageList", method: "GET" }, //查询
      driverUpdate: { url: "/driver/update" }, //修改
      driverDelete: { url: "/driver/deletes" }, //删除
      driverAdd: { url: "/driver/add" }, //添加
      driverDetail: { url: "/driver/get", method: "GET" }, //详情查询
      availableDriverList: { url: "/driver/available_pageList", method: "GET" }, //查询
      //IC卡
      icCardList: { url: "/icCard/pageList", method: "GET" }, //查询
      icCardUpdate: { url: "/icCard/update" }, //修改
      icCardDelete: { url: "/icCard/deletes" }, //删除
      icCardAdd: { url: "/icCard/add" }, //添加
      icCardDetail: { url: "/icCard/get", method: "GET" }, //详情查询
      //仓库管理
      warehouseList: { url: "/warehouse/pageList", method: "GET" }, //查询
      warehouseUpdate: { url: "/warehouse/update" }, //修改
      warehouseDelete: { url: "/warehouse/deletes" }, //删除
      warehouseAdd: { url: "/warehouse/add" }, //添加
      warehouseDetail: { url: "/warehouse/get", method: "GET" }, //详情查询
      //物料管理
      materialAdd: { url: "/material/add", method: "Post" }, //添加
      materialList: { url: "/material/pageList", method: "GET" }, //查询
      materialUpdate: { url: "/material/update" }, //修改
      materialDelete: { url: "/material/deletes" }, //删除
      materialTypeUpdate: { url: "/materialType/update" }, //类型修改
      materialTypeAdd: { url: "/materialType/add" }, //类型添加
      materialTypeDelete: { url: "/materialType/delete" }, //类型删除
      materialDetail: { url: "/material/get", method: "GET" }, //详情查询
      //矿口管理
      minesiteList: { url: "/minesite/pageList", method: "GET" }, //查询
      minesiteUpdate: { url: "/minesite/update" }, //修改
      minesiteDelete: { url: "/minesite/deletes" }, //删除
      minesiteAdd: { url: "/minesite/add" }, //添加
      minesiteDetail: { url: "/minesite/get", method: "GET" }, //详情查询
      //堆场管理
      yardList: { url: "/yard/pageList", method: "GET" }, //查询
      yardUpdate: { url: "/yard/update" }, //修改
      yardDelete: { url: "/yard/deletes" }, //删除
      yardAdd: { url: "/yard/add" }, //添加
      //成品库管理
      inventoryList: { url: "/inventory/pageList", method: "GET" }, //查询
      inventoryUpdate: { url: "/inventory/update" }, //修改
      inventoryDelete: { url: "/inventory/deletes" }, //删除
      inventoryAdd: { url: "/inventory/add" }, //添加
      inventoryDetail: { url: "/inventory/get", method: "GET" }, //详情查询
      // IoT设备
      iotAddDevice: { url: "/iot/addDevice" }, //创建设备
      iotCommandDevice: { url: "/iot/commandDevice" }, //下发设备命令
      iotMsgDevice: { url: "/iot/messagesDevice" }, //下发消息
      iotDeleteDevice: { url: "/iot/deleteDevice", method: "GET" }, //删除设备
      iotDeviceQuery: { url: "/iot/device", method: "GET" }, //查询设备
      iotDeviceLists: { url: "/iot/devices" }, //设备列表
      iotDeviceAttributes: { url: "/iot/commandDevice" }, //接受设备属性
      iotUpdateDevice: { url: "/iot/updateDevice" }, //修改设备
      //iot产品
      iotProductLists: { url: "/iot/products" }, //产品列表
      iotProductQuery: { url: "/iot/product", method: "GET" }, //查询产品

      //销售超载阈值
      saleOverloadPageList: { url: "/saleOverload/pageList", method: "GET" }, //查询
      saleOverloadAdd: { url: "/saleOverload/add", method: "Post" }, //新增
      saleOverloadEdit: { url: "/saleOverload/update", method: "Post" }, //编辑
      saleOverloadDel: { url: "/saleOverload/delete", method: "Post" }, //删除
    },
    // 搜索条件
    global: {
      customerList: { url: "/customer/pageList", method: "GET" }, //客户查询
      materialList: { url: "/material/pageList", method: "GET" }, //物料查询
      vehicleList: { url: "/vehicle/pageList", method: "GET" }, //车辆查询
      supplierList: { url: "/supplier/pageList", method: "GET" }, //查询供应商
      factoryList: { url: "/factory/pageList", method: "GET" }, //厂区、倒运单位
      orgPageList: { url: "/org/pageList", method: "GET" }, //查询组织
    },
    example: {
      select: { url: "/select", method: "get" }, // 查询
      insert: { url: "/insert" }, // 添加
      update: { url: "/update" }, // 修改
      delete: { url: "/deletes" }, // 删除
    },
    documents: {
      editVehicleInfo: { module: "", url: "/update/vehicle", method: "post" },
    },
    processSet: {
      query: { module: "", url: "/sysSet/list", method: "GET" },
      update: { module: "", url: "/sysSet/update", method: "post" },
    },
  },
};
