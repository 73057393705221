import { observable, action } from "mobx";
class GLobalStore {
  @observable isLogin = false; //是否登录
  @observable userInfo = null; //用户信息
  @observable factoryInfo = []; //厂区信息(登录的时候所选择的厂区)
  @observable factoryMap = []; //厂区列表
  @observable factoryObj = {}; //厂区列表(object)
  @observable isShowNotice = false; //是否有公告
  @observable isFullscreen = false; //是否全屏，默认false
  @observable globalRoutes = []; //用户的权限菜单
  @observable orgInfo = []; //组织信息(登录的时候所选择的厂区对应的组织)
  @observable orgInfoWithAll = []; //组织信息(同上+'全部')
  @observable buyIsExceptionAdd = false;
  @observable saleIsExceptionAdd = false; //组织信息(同上+'全部')
  constructor() {
    const token = localStorage.getItem("token");
    const orgInfo = localStorage.getItem("orgInfo");
    if (!token || !orgInfo) {
      this.isLogin = false;
      this.userInfo = null;
      this.factoryInfo = [];
      this.factoryMap = [];
      this.factoryObj = {};
      this.orgInfo = [];
    } else {
      this.isLogin = true;
      this.userInfo = JSON.parse(token);
      this.factoryInfo = [
        {
          value: this.userInfo.partyId,
          label: this.userInfo.partyName,
        },
      ];
      this.factoryMap =
        this.userInfo.partyDTOS &&
        this.userInfo.partyDTOS.map((item) => {
          this.factoryObj[item.partyId] = item.partyName;
          return {
            value: item.partyId,
            label: item.partyName,
          };
        });
      this.factoryMapWithAll = [{ value: "", label: "全部" }].concat(
        this.factoryMap
      );
      this.orgInfo = JSON.parse(orgInfo);
      this.orgInfoWithAll = [{ value: "", label: "全部" }].concat(this.orgInfo);
    }
  }

  @action login = (token) => {
    this.isLogin = true;
    this.userInfo = token;
    this.factoryInfo = [
      {
        value: this.userInfo.partyId,
        label: this.userInfo.partyName,
      },
    ];
    this.factoryMap = this.userInfo.partyDTOS.map((item) => {
      this.factoryObj[item.partyId] = item.partyName;
      return {
        value: item.partyId,
        label: item.partyName,
      };
    });
    if (typeof token != "string") token = JSON.stringify(token);
    localStorage.setItem("token", token);
  };
  @action saveOrgInfo = (orgs) => {
    let orgInfo = orgs.map((item) => {
      if (item) {
        return {
          value: item.id,
          label: item.name,
        };
      }
    });
    this.orgInfo = orgInfo;

    this.orgInfoWithAll = [{ value: "", label: "全部" }].concat(this.orgInfo);
    localStorage.setItem("orgInfo", JSON.stringify(orgInfo));
  };

  @action logout = () => {
    this.isLogin = false;
    localStorage.removeItem("token");
  };
  @action transNotice = (val) => {
    this.isShowNotice = val;
  };
  @action setFullscreen = (val) => {
    this.isFullscreen = val;
  };
  @action setGlobalRoutes = (val) => {
    this.globalRoutes = val;
  };
  //采购异常次数判断
  @action setBuyIsExceptionAdd = (val) => {
    this.buyIsExceptionAdd = !!val;
  };
  //销售异常次数判断
  @action setSaleIsExceptionAdd = (val) => {
    this.saleIsExceptionAdd = val;
  };
}

export default new GLobalStore();
