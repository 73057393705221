import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Header from '@/components/Header'
import Notice from '@/components/Notice'
import MainContent from '@/components/MainContent';

@inject('GlobalStore')
@observer
class Index extends Component {

  componentWillMount() {
    if (!this.props.GlobalStore.isLogin) this.props.history.replace('/login');
    else if (this.props.history.location.pathname === '/') this.props.history.replace('/home');
    this.props.history.listen(param => {
      if (param.pathname === '/') this.props.history.replace('/home');
    })
    // routers
  }
  render() {


    return (
        <div id="index-root">
          <Header />
          {
            this.props.GlobalStore.isShowNotice ? <Notice /> : null
          }
          <MainContent />



        </div>
    );
  }
}

export default Index
