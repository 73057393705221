import React, { Component } from 'react';
import "@/utils/http.js";
import { Provider } from 'mobx-react';
import { Route,Redirect, Switch, HashRouter as Router } from 'react-router-dom';
import { routeView } from '@/router/index.js'
import { ConfigProvider } from 'antd';

import stores from '@/stores';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import '@/assets/common/index.scss';
import 'antd/dist/antd.css';
import './App.css';
class App extends Component {

  render() {
    return (
      <Provider {...stores}>
        <Router>
          <ConfigProvider locale={zhCN}>
            <Switch>
              {routeView()}
            </Switch>
          </ConfigProvider>
        </Router>
      </Provider>
    )
  }
}
export default App;
