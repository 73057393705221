import React, { Component } from 'react'
import routersConfig from '@/router/config';
import { loopRoutes } from '@/utils/tool.js';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import "./index.scss";
import { Menu, Dropdown } from 'antd';

const routers = loopRoutes(routersConfig);

@inject('GlobalStore')
@observer
class index extends Component {
    state = {
        currentPageKey: '',
        historyStack: [],
        menuItemWidth: 120,
        historyStorage: [],
        isCollect: false
    }
    componentWillMount() {

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setTagWidth);
    }
    componentDidMount() {
        this.initMenu();
        window.addEventListener('resize', this.setTagWidth)
        this.props.history.listen(param => {
            // console.log('history-listen2',param);
            this.changeRoute(`#${param.pathname}`);

        })

    }
    initMenu = () => {
        const collectKey = 'menuCollect' + '__' + this.props.GlobalStore.userInfo.id;
        let menuCollect = localStorage.getItem(collectKey);
        if (menuCollect) {
            menuCollect = JSON.parse(menuCollect);
            this.setState({
                historyStack: JSON.parse(JSON.stringify(menuCollect)),
                historyStorage: JSON.parse(JSON.stringify(menuCollect))
            }, () => {
                this.changeRoute(document.location.href);
                this.setTagWidth();
            })
        } else {
            this.changeRoute(document.location.href);
            this.setTagWidth();
        }
    }
    setTagWidth = () => {
        const autoMenuDom = document.querySelector('#menuHistory .autoMenu');
        if (!autoMenuDom) return;
        const autoMenuWidth = autoMenuDom.offsetWidth;
        const n = this.state.historyStack.length;
        if (!n) return;
        if (autoMenuWidth / n >= 120) {
            this.setState({ menuItemWidth: 120 });
        } else {
            const smallWidth = autoMenuWidth / n;
            this.setState({ menuItemWidth: smallWidth });
        }

    }
    // 切换标签
    changeRoute = (url) => {
        const autoMenuDom = document.querySelector('#menuHistory .autoMenu');
        if (!autoMenuDom) return;
        const key = url.split('#/')[1];
        this.setState({
            currentPageKey: key
        })
        // if(autoMenuDom.offsetWidth/(this.state.historyStack.length + 1)<=  60) return;
        for (let item of routers) {
            if (item.key === key) {
                let isHas = false;
                for (let it of this.state.historyStack) {
                    if (it.key === key) {
                        isHas = true;
                        break;
                    }
                }
                if (!isHas) {
                    const historyStack = this.state.historyStack;
                    historyStack.push(item);
                    this.setState({ historyStack });
                    this.setTagWidth();
                }


                return;

            }
        }

    }
    // 删除标签
    deleteRoute = (key, e) => {
        e.stopPropagation();
        const historyStack = this.state.historyStack;
        const i = historyStack.findIndex(item => item.key === key);
        historyStack.splice(i, 1);
        this.setState({ historyStack });
        this.setTagWidth();
        if (this.state.currentPageKey === 'home') return;
        if (!historyStack.length) {
            this.props.history.push('/home');

        } else if (key === this.state.currentPageKey) {
            const nextHistory = historyStack[i] || historyStack[i - 1];
            this.props.history.push(nextHistory.path);
        }
    }
    link = path => {
        this.props.history.push(path);
    }
    contextMenu = ({ key, domEvent }) => {
        domEvent.stopPropagation();
        const keyArr = key.split('__');
        switch (keyArr[1]) {
            case '1':
                this.closeLeft(keyArr[0]);
                break;
            case '2':
                this.closeRight(keyArr[0]);
                break;
            case '3':
                this.closeOther(keyArr[0]);
                break;
            case '4':
                this.triggerCollect(keyArr[0]);
                break;
        }
    }
    //关闭左侧
    closeLeft = key => {
        const historyStack = this.state.historyStack;
        if (historyStack.length === 1) return;

        let isDelCurrentKey = false;
        const currentIndex = historyStack.findIndex(item => {
            if (item.key === key) {
                return true;
            } else {
                if (this.state.currentPageKey === item.key) isDelCurrentKey = true;
            }
        });
        historyStack.splice(0, currentIndex);
        this.setState({ historyStack },()=>{
            this.setTagWidth();
        });
        if (this.state.currentPageKey != 'home' && isDelCurrentKey) {
            const nextPath = historyStack[0].path || '/home';
            this.link(nextPath);
        }
    }
    //关闭右侧
    closeRight = key => {
        const historyStack = this.state.historyStack;
        if (historyStack.length === 1) return;

        let isDelCurrentKey = true;
        const currentIndex = historyStack.findIndex(item => {
            if (this.state.currentPageKey === item.key) isDelCurrentKey = false;
            return item.key === key
        });
        historyStack.splice(currentIndex + 1);
        this.setState({ historyStack },()=>{
            this.setTagWidth();
        });
        if (this.state.currentPageKey != 'home' && isDelCurrentKey) {
            const nextPath = historyStack[historyStack.length - 1].path || '/home';
            this.link(nextPath);
        }
    }
    //关闭其他
    closeOther = key => {
        let historyStack = this.state.historyStack;
        if (historyStack.length === 1) return;

        const currentIndex = historyStack.findIndex(item => {
            return item.key === key
        });
        historyStack = [historyStack[currentIndex]];
        this.setState({ historyStack },()=>{
            this.setTagWidth();
        });
        if (this.state.currentPageKey != 'home') {
            const nextPath = historyStack[0].path || '/home';
            this.link(nextPath);
        }
    }
    //添加|删除书签
    triggerCollect = key => {
        if (this.state.isCollect) { //删除
            const historyStorage = this.state.historyStorage;
            const i = historyStorage.findIndex(item => item.key === key);
            const collectKey = 'menuCollect' + '__' + this.props.GlobalStore.userInfo.id;

            historyStorage.splice(i, 1);
            this.setState({ historyStorage });
            localStorage.setItem(collectKey, JSON.stringify(historyStorage));

        } else { //添加
            const historyStorage = this.state.historyStorage;
            const historyStack = this.state.historyStack;
            const c = historyStack.find(item => item.key === key);
            const collectKey = 'menuCollect' + '__' + this.props.GlobalStore.userInfo.id;

            let menuCollect = localStorage.getItem(collectKey);
            if (!menuCollect) menuCollect = JSON.stringify([]);
            menuCollect = JSON.parse(menuCollect);
            menuCollect.push(c);
            historyStorage.push(c);
            localStorage.setItem(collectKey, JSON.stringify(menuCollect));
            this.setState({ historyStorage });


        }

    }
    onVisibleChange = (visible, key) => {
        if (visible) {
            console.log(key, this.state.historyStorage)
            const i = this.state.historyStorage.findIndex(item => item.key === key);
            this.setState({
                isCollect: i >= 0 ? true : false
            })

        }

    }
    renderCollect = key => {
        const historyStorage = this.state.historyStorage;
        const i = historyStorage.findIndex(item => item.key === key);
        if (i >= 0) {
            return (
                <div className="collectBox">

                </div>
            )
        } else {
            return null;
        }

    }
    render() {

        return (
            <div id="menuHistory">
                <a className={`homeMenu ${this.state.currentPageKey === 'home' ? 'active' : ''}`} href="#/home">
                    首页
                </a>
                <div className="autoMenu">
                    {
                        this.state.historyStack.map(item =>
                            <div onClick={() => this.link(item.path)}
                                className={`menuItem ${this.state.currentPageKey === item.key ? 'active' : ''}`}
                                style={{ width: this.state.menuItemWidth + 'px' }}
                                key={item.key}>
                                <Dropdown overlay={
                                    <Menu onClick={this.contextMenu}>
                                        <Menu.Item key={item.key + '__' + 1}>
                                            <div className="menuHistory-userArea">
                                                <span>关闭左侧标签</span>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item key={item.key + '__' + 2}>
                                            <div className="menuHistory-userArea">
                                                <span>关闭右侧标签</span>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item key={item.key + '__' + 3}>
                                            <div className="menuHistory-userArea">
                                                <span>关闭其他标签</span>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item key={item.key + '__' + 4}>
                                            <div className="menuHistory-userArea">
                                                <span>{this.state.isCollect ? '删除' : '添加'}书签</span>
                                            </div>
                                        </Menu.Item>
                                    </Menu>


                                } trigger={['contextMenu']} onVisibleChange={(visible) => { this.onVisibleChange(visible, item.key) }}>
                                    <div style={{ padding: '0 12px' }} >
                                        <span className="label overflow_1" >{item.label}</span>
                                        <span className="close" onClick={e => this.deleteRoute(item.key, e)}>×</span>
                                        {
                                            this.renderCollect(item.key)
                                        }
                                    </div>
                                </Dropdown>
                            </div>
                        )

                    }
                </div>
            </div>
        )
    }
}

export default withRouter(index)